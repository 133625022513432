<div id="clientes-insert-edit">
  <div
    class="container-data"
  >
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs default-tabs">
          <li class="nav-item" (click)="viewPage = 'INFO'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'INFO'" [class.error]="subPageInfo?.error">
              Informações</h4>
          </li>
          <li class="nav-item" (click)="viewPage = 'CONTRATO'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'CONTRATO'"
                [class.error]="subPageContrato.error">Contratos</h4>
          </li>
          <li *ngIf="!session.checkPerfil('PARCEIRO') && entity?.id" class="nav-item" (click)="viewPage = 'COLETA'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'COLETA'">Estrutura de Coleta</h4>
          </li>
          <li class="nav-item" (click)="viewPage = 'MTR'">
            <h4 class="nav-link card-title" [class.active]="viewPage == 'MTR'">Ambiental</h4>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- INFORMAÇÕES -->
  <div class="container-data" [hidden]="viewPage != 'INFO'">

    <subpage-cliente-sub-page-informacoes
      #subPageInfo
      [entity]="entity"
    >
    </subpage-cliente-sub-page-informacoes>
  </div>

  <!-- CONTRATO -->
  <div class="container-data" [hidden]="viewPage != 'CONTRATO'">
    <subPage-cliente-sub-page-contrato
      #subPageContrato
      [clienteId]="entityId || entity?.id"
      [entity]="entity"
    ></subPage-cliente-sub-page-contrato>
  </div>

  <!-- ESTRUTURA COLETAS -->
  <div class="container-data mt-3" [hidden]="viewPage != 'COLETA'">
    <div
      id="perguntas-unidade-geradora"
      *ngIf="entity?.id && !session.checkPerfil(['GALPAO'])"
    >
      <subComponent-pergunta-casa
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('CASA')"
      ></subComponent-pergunta-casa>
      <subComponent-pergunta-condominio
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('CONDOMINIO')"
      ></subComponent-pergunta-condominio>
      <subComponent-pergunta-empresa
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('EMPRESA')"
      ></subComponent-pergunta-empresa>
      <subComponent-pergunta-evento
        [clienteId]="entity.id"
        *ngIf="checkPerfilPergunta('EVENTO')"
      ></subComponent-pergunta-evento>
    </div>
  </div>

  <!-- MTR -->
  <div class="container-data" [hidden]="viewPage != 'MTR'">
    <subPage-cliente-sub-page-mtr
      #subPageMTR
      [entity]="entity"
    >
    </subPage-cliente-sub-page-mtr>
  </div>

</div>


<page-footer *ngIf="!session.checkProfile('USUARIO_CONSULTA')">
  <button
    crud-voltar
    class="btn btn-secondary"
  >
    Voltar
  </button>
  <button
    *ngIf="checkPermission()"
    crud-salvar
    dg-desable-by-period
    [backAfterSave]="false"
    class="btn btn-success"
  >
    Salvar
  </button>
  <button
    *ngIf="checkPermission()"
    crud-salvar
    class="btn btn-success"
  >
    Salvar e Voltar
  </button>
  <button
    *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])"
    crud-deletar
    class="btn btn-danger"
  >
    Excluir
  </button>
  <button
    *ngIf="session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR']) || (session.checkPerfil(['GALPAO']) && entity?.exclusivo) || (session.checkPerfil(['PARCEIRO']) && entity?.isCreated)"
    class="btn"
    (click)="ativarDesativarCliente()"
    [class.btn-danger]="entity?.ativo"
    [class.btn-success]="!entity?.ativo"
  >
    {{entity?.ativo ? 'desativar' : 'ativar'}} cliente
  </button>
</page-footer>
