import {AbstractEntity, DataServer, DgActionsTable, DgTableColumn} from "@datagrupo/dg-crud";
import {ArquivoEntity} from "../../../../shared/entitys/arquivo.entity";
import {
  ActionsArquivosClienteService
} from "../arquivo-clientes/service/actions-table/actions-arquivos-cliente.service";
import {environment} from "../../../../../environments/environment";
import {ARQUIVO_CLIENTE} from "../../../../core/config/endpoint-list";

const formataData = (dataString: string) => {
  var data = new Date(dataString),
  dia = (data.getDate() + 1).toString(),
  diaF = (dia.length == 1) ? '0' + dia : dia,
  mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  mesF = (mes.length == 1) ? '0' + mes : mes,
  anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
}
@DataServer({
  path: environment.apiUrl,
  context: ARQUIVO_CLIENTE
})
@DgActionsTable(ActionsArquivosClienteService)
export class ArquivosClientesEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    dataPagamento?: string,
    pago?: boolean,
    arquivo?: ArquivoEntity,
    fileName?: string
  ) {
    super();
    this.id = id;
    this.dataPagamento = dataPagamento;
    this.pago = pago;
    this.arquivo = arquivo;
    this.fileName = fileName;
  }

  @DgTableColumn({columnName: 'Arquivo', sort: true, resource: (val: ArquivoEntity) => !!val?.fileNameOriginal ? val.fileNameOriginal : '--'})
  public arquivo: ArquivoEntity | undefined;
  @DgTableColumn({ columnName: 'Data de inclusão', sort: true, resource: formataData})
  public dataPagamento: string | undefined;
  @DgTableColumn({ columnName: 'Utilizado', sort: true, resource: (val: boolean) => val ? 'Sim' : 'Não'})
  public pago: boolean | undefined;
  public fileName: string | undefined;
}
