import { Component, OnInit } from '@angular/core';
import {IndicadoresEntity} from "../../indicadores.entity";
import {IndicadoresService} from "../../service/indicadores.service";

@Component({
  selector: 'app-indicadores-regionais-main',
  templateUrl: './indicadores-regionais-main.component.html',
  styleUrls: ['./indicadores-regionais-main.component.scss']
})
export class IndicadoresRegionaisMainComponent implements OnInit {
  public mapEntity = new IndicadoresEntity();

  constructor(public service: IndicadoresService) {}

  ngOnInit(): void {
  }

}
