import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicadoresMainComponent } from './indicadores-main/indicadores-main.component';
import { UiModule } from "../../../shared/ui/ui.module";
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { IndicadoresRegionaisMainComponent } from './indicadores-regionais/indicadores-regionais-main/indicadores-regionais-main.component';
import { IndicadoresRegionaisInsertEditComponent } from './indicadores-regionais/indicadores-regionais-insert-edit/indicadores-regionais-insert-edit.component';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [
        IndicadoresMainComponent,
        IndicadoresRegionaisMainComponent,
        IndicadoresRegionaisInsertEditComponent
    ],
    imports: [
        CommonModule,
        UiModule,
        DgCrudModule,
        ReactiveFormsModule,
        RouterModule,
        DirectivesModule,
        MatTooltipModule

    ]
})
export class IndicadoresModule { }
