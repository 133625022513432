import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {ContainerClienteEntity} from "../../../_entitys/container-cliente.entity";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContainerEntity} from "../../../../container/container.entity";
import {TipoLixoEntity} from "../../../../tipo-lixo/tipo-lixo.entity";
import {UnidadeMedidaEntity} from "../../../../unidade-medida/unidade-medida.entity";
import {ContainerClienteService} from "../../../container-cliente/service/container-cliente.service";
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import {InputHelpers} from "../../../../../../helpers/input.helpers";
import {GalpaoEntity} from "../../../../_galpao/galpao/galpao.entity";
import {CLIENTE_CONTAINER} from "../../../../../../core/config/endpoint-list";
import {TratamentoEntity} from "../../../../tratamento/tratamento.entity";
import {GalpaoTipoLixoEntity} from "../../../../_galpao/galpao/galpao-tipo-lixo.entity";
import {IntegracaoMtrEntity} from "../../integracao-mtr.entity";
import Swal from "sweetalert2";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {ClassesResiduosEntity} from "../../classesResiduos.entity";
import {EstadoFisicoEntity} from "../../estado-fisico.entity";
import {GrupoTipoResiduoEntity} from "../../../_entitys/grupo-tipo-residuo.entity";
import {IndustriaDestinoEntity} from "../../../../industria-destino/industria-destino.entity";
import {
  DefaultAutocompleteInputComponent
} from "../../../../../../shared/custom-autocomplete/default-autocomplete-input/default-autocomplete-input.component";

@Component({
  selector: 'modal-cliente-relacao-contrato',
  templateUrl: './modal-cliente-relacao-contrato.component.html',
  styleUrls: ['./modal-cliente-relacao-contrato.component.scss']
})
export class ModalClienteRelacaoContratoComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent
  @ViewChild('inputIndustria') inputIndustria!: DefaultAutocompleteInputComponent;

  @Input() idCliente: number | string | undefined;

  @Output('afterSave') afterSave = new EventEmitter();

  public entity = new ContainerClienteEntity();

  public disabled = false;
  public tableVisible: string = 'relacao-contrato';

  public form = new FormGroup({
    id: new FormControl(''),
    identificacao: new FormControl('', [Validators.required]),
    galpao: new FormControl('', [Validators.required]),
    container: new FormControl({value: '', disabled: true}, [Validators.required]),
    tipoLixo: new FormControl({value: '', disabled: true}, [Validators.required]),
    tratamento: new FormControl('', [Validators.required]),
    unidadeMedidaPadrao: new FormControl('', [Validators.required]),
    quantidade: new FormControl('', [Validators.required]),
    industria: new FormControl('', [Validators.required]),
    listUnidadeMedida: new FormControl('', [Validators.required]),
    dono: new FormControl(''),
    usarArmazenadorTemporario: new FormControl(false),
    armazenadorTemporario: new FormControl({disabled: true, value: ''}, [Validators.required]),
  })
  public formMTR = new FormGroup({
    classeResiduo: new FormControl('', [Validators.required]),
    densidade: new FormControl(''),
    estadoFisico: new FormControl('', [Validators.required]),
    grupoTipoResiduo: new FormControl(''),
    custoToneladaDestinacao: new FormControl(''),
    custoTransporte: new FormControl(''),
  })

  public listEstado: any[] = [];
  public listClasses: any[] = [];
  public listContainers: ContainerEntity[] = [];
  public listTipoLixo: TipoLixoEntity[] = [];
  public listUnidadeMedida: UnidadeMedidaEntity[] = [];
  public listTratamento: UnidadeMedidaEntity[] = [];
  public listIndustria: GalpaoEntity[] = [];
  public listGrupoTipoResiduo: GrupoTipoResiduoEntity[] = [];

  constructor(
    public service: ContainerClienteService,
    public session: SessionService,
    public http: HttpService
  ) {
    this.form.controls['usarArmazenadorTemporario'].valueChanges.subscribe(val => {
      this.form.controls['armazenadorTemporario'][!!val ? 'enable' : 'disable']()
    })
    this.form.controls['tipoLixo'].valueChanges.subscribe(val => {
      if (!val || this.disabled || this.entity?.id) return;
      this.http.get('unidade-medida/tipo-residuo/'+val, {params: {unpaged: true}}).subscribe(
        resp => {
          this.listUnidadeMedida = resp.data
        }
      )
      // this.form.controls['armazenadorTemporario'][!!val ? 'enable' : 'disable']()
    })
  }

  ngOnInit(): void {
    this.formMTR.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.formMTR, v, ['densidade', 'custoToneladaDestinacao', 'custoTransporte'])
    )
  }

  async buscarRelacoes(ev: GalpaoEntity) {
    if (!!ev?.id) {
      await this.http.get('galpao-containers/', {params: {galpaoId: ev.id, ativo: true, unpaged: true}}).subscribe(
        resp => {
          this.listContainers = resp.data;
        }
      )
      await this.http.get('tipo-lixo-galpao/' + ev.id, {params: {unpaged: true}}).subscribe(
        resp => {
          this.listTipoLixo = resp.data
        }
      )

      this.entity['galpao'] = ev;
      if (!this.disabled) {
        this.form.controls['container'].enable();
        this.form.controls['tipoLixo'].enable();
      }

    } else {
      this.listContainers = [];
      this.listTipoLixo = [];
      this.form.controls['container'].disable();
      this.form.controls['tipoLixo'].disable();
    }
  }

  async open(val?: ContainerClienteEntity) {

    if (!!val) {
      this.disabled = true;
      await this.http.get(CLIENTE_CONTAINER + '/' + val?.id, {params: {unpaged: true}}).subscribe(
        resp => {
          this.entity = {
            ...this.entity,
            ...resp.data
          }

          this.buscarRelacoes(resp.data.galpao);

          let listUnidadeMedida = '';

          if (Array.isArray(resp.data.listUnidadeMedida)) {
            listUnidadeMedida = resp.data.listUnidadeMedida.map((unidade: UnidadeMedidaEntity) => unidade.nome).join(', ')
          }

          this.form.patchValue({
            id: resp.data.id,
            container: resp.data.container?.nome,
            identificacao: resp.data.identificacao,
            galpao: resp.data.galpao?.nome,
            tipoLixo: resp.data.tipoLixoContainer?.nome,
            tratamento: resp.data.tratamento?.nome,
            unidadeMedidaPadrao: resp.data.unidadeMedidaPadrao?.nome,
            listUnidadeMedida: listUnidadeMedida,
            quantidade: resp.data.quantidade,
            industria: resp.data.industria?.id,
            dono: resp.data.dono,
            armazenadorTemporario: resp.data.integracaoMtr?.armazenadorTemporario?.id,
            usarArmazenadorTemporario: !!resp.data.integracaoMtr?.armazenadorTemporario?.id,
          })

          this.formMTR.patchValue({
            densidade: !this.entity.integracaoMtr?.densidade ? '' : InputHelpers.initInputDecimal(String(this.entity.integracaoMtr?.densidade)),
            classeResiduo: this.entity.integracaoMtr?.classeResiduo?.id,
            estadoFisico: this.entity.integracaoMtr?.estadoFisico?.id,
            grupoTipoResiduo: this.entity.grupoTipoResiduo?.id,
            custoToneladaDestinacao: InputHelpers.initInputDecimal(this.entity.custoToneladaDestinacao || 0),
            custoTransporte: InputHelpers.initInputDecimal(this.entity.custoTransporte || 0),
          })

          this.form.controls['container'].disable()
          this.form.controls['galpao'].disable()
          this.form.controls['tipoLixo'].disable()
          this.form.controls['tratamento'].disable()
          this.form.controls['unidadeMedidaPadrao'].disable()
          this.form.controls['listUnidadeMedida'].disable()
          this.form.controls['quantidade'].disable()
          // this.formMTR.controls['classeResiduo'].disable()
          // this.formMTR.controls['estadoFisico'].disable()
          // this.form.controls['industria'].disable()

          // if (this.entity.integracaoMtr?.densidade) {
          //   this.formMTR.controls['densidade'].disable()
          // }

          if (!this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR'])) {
            this.form.controls['industria'].disable()
          }
        }
      )

    } else {
      if (this.session.checkPerfil(['EVENTO', 'CONDOMINIO', 'CASA', 'EVENTO'])) {
        this.form.patchValue({dono: 'CLIENTE'})
      } else if (this.session.checkPerfil(['GALPAO'])) {
        this.form.patchValue({dono: 'GALPAO'})
      }

      await this.http.get('tratamento', {params: {unpaged: true, sort: 'nome,asc'}}).subscribe(
        resp => {
          if (!this.disabled) {
            this.listTratamento = (resp.data || []).sort((a: TratamentoEntity, b: TratamentoEntity) => {
              if ((a.nome || '').toUpperCase() > (b.nome || '').toUpperCase()) {
                return 1;
              }
              if ((a.nome || '').toUpperCase() < (b.nome || '').toUpperCase()) {
                return -1;
              }
              // a must be equal to b
              return 0;
            })
          }
        }
      )
    }

    this.http.get(`industria/nomes`, { params: { unpaged: true, filtrar: false } }).subscribe(
      (resp: any) => {
        this.listIndustria = resp.data;
      }
    )

    this.http.get(`estados-fisicos`).subscribe(
      (resp: any) => {
        this.listEstado = resp.data;
      }
    )

    this.http.get(`classes-residuos`).subscribe(
      (resp: any) => {
        this.listClasses = resp.data;
      }
    )

    this.http.get(`grupos-tipos-residuos`, { params: {unpaged:true} }).subscribe(
      (resp: any) => {
        this.listGrupoTipoResiduo = resp.data ?? [];
      }
    )

    this.modal.open()
  }

  private _ajusteDataSave(): { [key: string]: any } {
    const {
      id,
      galpao,
      industria,
      container,
      tipoLixo,
      unidadeMedidaPadrao,
      listUnidadeMedida,
      tratamento,
      ...formV
    } = this.form.value;

    const {
      classeResiduo,
      estadoFisico,
      densidade,
      grupoTipoResiduo,
      custoToneladaDestinacao,
      custoTransporte,
    } = this.formMTR.value

    this.entity['clienteId'] = this.idCliente;

    // relacionando container
    const indexContainer = this.listContainers.findIndex(val => val.id == container);
    if (indexContainer == -1) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione um container'
      }).then()
      throw 'Selecione um container';
    }
    this.entity['container'] = <ContainerEntity>{
      id: this.listContainers[indexContainer].id,
      nome: this.listContainers[indexContainer].nome
    }

    // relacionando tipoLixoContainer
    const indexTipoLixo = this.listTipoLixo.findIndex(val => val.id == tipoLixo);
    if (indexTipoLixo == -1) {
      Swal.fire({
        icon: 'warning',
        title: 'Informe o tipo de resíduo'
      }).then()
      throw 'Informe o tipo de resíduo';
    }
    // @ts-ignore
    this.entity['tipoLixoContainer'] = <GalpaoTipoLixoEntity>{
      id: this.listTipoLixo[indexTipoLixo].id
    }

    this.entity.listUnidadeMedida = <UnidadeMedidaEntity[]>listUnidadeMedida?.map(
      (unidade: number) => {
        return {id: unidade}
      })

    this.entity.unidadeMedidaPadrao = <UnidadeMedidaEntity>{id: unidadeMedidaPadrao}

    this.entity.tratamento = <TratamentoEntity>{id: tratamento}

    return {
      ...this.entity,
      custoToneladaDestinacao: Number(InputHelpers.ajusteMoneyBrToEua(custoToneladaDestinacao)),
      custoTransporte: Number(InputHelpers.ajusteMoneyBrToEua(custoTransporte)),
      integracaoMtr: {
        classeResiduo: {
          id: classeResiduo,
        },
        estadoFisico: {
          id: estadoFisico
        },
        densidade: Number(InputHelpers.ajusteMoneyBrToEua(densidade))
      },
      grupoTipoResiduo: !!grupoTipoResiduo ? {id: grupoTipoResiduo} : null,
      ...formV
    }
  }


  // ajustando data para edição dos dados
  private _ajustData() {
    const {
      id,
      galpao,
      industria,
      container,
      tipoLixo,
      unidadeMedidaPadrao,
      listUnidadeMedida,
      tratamento,
      identificacao,
      dono,
      armazenadorTemporario,
      ...formV
    } = this.form.value;

    const {
      classeResiduo,
      estadoFisico,
      densidade,
      custoToneladaDestinacao,
      custoTransporte,
      grupoTipoResiduo
    } = this.formMTR.getRawValue()

    this.entity['clienteId'] = this.idCliente;

    this.entity = {
      ...this.entity,
      grupoTipoResiduo: !!grupoTipoResiduo ? <GrupoTipoResiduoEntity>{id: grupoTipoResiduo} : undefined,
      custoToneladaDestinacao: Number(InputHelpers.ajusteMoneyBrToEua(custoToneladaDestinacao)),
      custoTransporte: Number(InputHelpers.ajusteMoneyBrToEua(custoTransporte)),
      industria: this.inputIndustria.selectedList[0] || undefined,
      integracaoMtr: <IntegracaoMtrEntity>{
        classeResiduo: <ClassesResiduosEntity>{
          id: classeResiduo,
        },
        estadoFisico: <EstadoFisicoEntity>{
          id: estadoFisico
        },
        armazenadorTemporario: !armazenadorTemporario ? undefined : <GalpaoEntity>{id: armazenadorTemporario},
        densidade: Number(InputHelpers.ajusteMoneyBrToEua(densidade))
      }
    }

    if (!!id) {
      return {
        ...this.entity,
        identificacao,
        diasColeta: this.entity.diasColeta,
        dono,
        // integracaoMtr: {
        //   classeResiduo: {
        //     id: classeResiduo,
        //   },
        //   estadoFisico: {
        //     id: estadoFisico
        //   },
        //   densidade: !this.formMTR.controls['densidade'].disabled ? Number(InputHelpers.ajusteMoneyBrToEua(densidade)) : (this.entity.integracaoMtr?.densidade)
        // }
      }
    }

    // relacionando container
    const indexContainer = this.listContainers.findIndex(val => val.id == container);
    if (indexContainer == -1) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecione um container'
      }).then()
      throw 'Selecione um container';
    }
    this.entity['container'] = <ContainerEntity>{
      id: this.listContainers[indexContainer].id,
      nome: this.listContainers[indexContainer].nome
    }

    // relacionando tipoLixoContainer
    const indexTipoLixo = this.listTipoLixo.findIndex(val => val.id == tipoLixo);
    if (indexTipoLixo == -1) {
      Swal.fire({
        icon: 'warning',
        title: 'Informe o tipo de resíduo'
      }).then()
      throw 'Informe o tipo de resíduo';
    }
    // @ts-ignore
    this.entity['tipoLixoContainer'] = <GalpaoTipoLixoEntity>{
      id: this.listTipoLixo[indexTipoLixo].id
    }

    this.entity.listUnidadeMedida = <UnidadeMedidaEntity[]>listUnidadeMedida?.map(
      (unidade: number) => {
        return {id: unidade}
      })

    this.entity.unidadeMedidaPadrao = <UnidadeMedidaEntity>{id: unidadeMedidaPadrao}

    this.entity.tratamento = <TratamentoEntity>{id: tratamento}

    return {
      ...this.entity,
      // integracaoMtr: {
      //   classeResiduo: {
      //     id: classeResiduo,
      //   },
      //   estadoFisico: {
      //     id: estadoFisico
      //   },
      //   densidade: Number(InputHelpers.ajusteMoneyBrToEua(densidade))
      // },
      ...formV
    }
  }

  salvar() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.formMTR.invalid) {
      this.formMTR.markAllAsTouched();
      return;
    }

    if (!this.idCliente) return;

    let request;
    const data = this._ajustData()

    if (!!this.form.value.id) {
      // request = this.http.put(CLIENTE_CONTAINER, this._ajusteDataUpdate());
      request = this.http.put(CLIENTE_CONTAINER, data);
    } else {
      request = this.http.post(CLIENTE_CONTAINER, this._ajusteDataSave());
    }

    request.subscribe(() => {
        window.dispatchEvent(new CustomEvent('dg-table-atualizar-event', {detail: 'ContainerClienteEntity'}));
        Swal.fire({
          icon: 'success',
          title: 'Dados atualizados'
        }).then();
        this.afterSave.emit()
        this.modal.close();
      }
    )
  }

  beforeClose = () => {
    this.disabled = false;
    this.form.reset({
      id: '',
      galpao: '',
      container: '',
      identificacao: '',
      tipoLixo: '',
      tratamento: '',
      unidadeMedidaPadrao: '',
      dono: '',
      classeResiduo: [],
      estadoFisico: [],
      densidade: '',
      usarArmazenadorTemporario: false
    });
    this.formMTR.reset({
      classeResiduo: '',
      densidade: '',
      estadoFisico: '',
      grupoTipoResiduo: '',
      custoToneladaDestinacao: '',
      custoTransporte: '',
    });
    this.form.controls['container'].enable()
    this.form.controls['identificacao'].enable()
    this.form.controls['galpao'].enable()
    this.form.controls['tipoLixo'].enable()
    this.form.controls['tratamento'].enable()
    this.form.controls['unidadeMedidaPadrao'].enable()
    this.form.controls['listUnidadeMedida'].enable()
    this.form.controls['quantidade'].enable()
    this.form.controls['industria'].enable()
    this.form.controls['dono'].enable()
    // this.formMTR.controls['classeResiduo'].enable()
    // this.formMTR.controls['estadoFisico'].enable()
    // this.formMTR.controls['densidade'].enable()
    this.listContainers = [];
    this.listTipoLixo = [];
    this.entity = new ContainerClienteEntity();
    this.tableVisible = 'relacao-contrato';
  }

  verifyCheckBox(valor: string): boolean {
    const index = (this.entity?.diasColeta || []).findIndex(arrayDia => arrayDia == valor);

    return index != -1;
  }

  verifyDisable(): boolean {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      return false;
    }

    // if (this.disabled) return true;

    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      return this.form.value.dono != 'CLIENTE'
    }

    if (this.session.checkPerfil(['GALPAO'])) {
      return this.form.value.dono != 'GALPAO'
    }

    return false
  }

  editDiasSemana(valor: string) {
    const index = (this.entity?.diasColeta || []).findIndex(arrayDia => arrayDia == valor);
    if (index == -1) {
      this.entity.diasColeta.push(valor)
      return;
    }
    this.entity.diasColeta.splice(index, 1);
  }
}
