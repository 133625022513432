import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudDataTableComponent, DataServer, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {SessionService} from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import {ColetasEntity} from "../coletas.entity";
import {ColetasService} from "../services/coletas.service";
import {ContainerClienteEntity} from "../../../_clientes/_entitys/container-cliente.entity";
import {ColetaPendenteEntity} from "../coleta-pendente.entity";
import {CdkDgTable, CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {HttpService} from "../../../../../services/http-service/http.service";
import {helpersTableMTR, MtrEntity} from "../mtr.entity";
import {environment} from "../../../../../../environments/environment";
import {RotasEntity} from "../../../rotas/rotas.entity";
import {ClientesEntity} from "../../../_clientes/clientes/clientes.entity";
import {FormControl, FormGroup} from "@angular/forms";
import {GalpaoMotoristaEntity} from "../../../_galpao/galpao-motorista/galpao-motorista.entity";
import {TipoLixoEntity} from "../../../tipo-lixo/tipo-lixo.entity";

class ContainerColetaMensal extends ContainerClienteEntity {
  selected: boolean = false;
  hasMtr: boolean = false;
  pesoMtr: string = '';
}

@Component({
  selector: 'app-coletas-main',
  templateUrl: './coletas-main.component.html',
  styleUrls: ['./coletas-main.component.scss']
})
export class ColetasMainComponent implements OnInit, OnDestroy {

  public tableVisible: string = 'coletas';

  tableMTR: CdkDynamicTable.tableClass;
  tableColetas: CdkDynamicTable.tableClass;
  helpersTableMTR = helpersTableMTR(this.http, this.session)

  listClientes: ClientesEntity[] = [];
  listMotorista: GalpaoMotoristaEntity[] = [];
  listResiduos: TipoLixoEntity[] = [];

  public mapEntityCanceladas = new ColetasEntity();
  @ViewChild('crudTable') crudTable!: CrudDataTableComponent
  public mapEntityPendentes = new ColetaPendenteEntity();

  public formFiltersColeta = new FormGroup({
    dataInicial: new FormControl(''),
    dataFinal: new FormControl(''),
    clienteId: new FormControl(''),
    motoristaId: new FormControl(''),
    codigoMtr: new FormControl(''),
    tipoResiduoId: new FormControl(''),
  });

  public customAction: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: ColetasEntity) => {
        this.router.navigate(['user/operacional/coletas/coletas/' + val.id]).then()
      },
      permission: this.session.checkProfile(['SINDICO', 'CONSELHO']) || this.session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'PARCEIRO'])
    },
    {
      name: 'Excluir',
      action: (val: ColetasEntity) => {
        Swal.fire({
          icon: 'warning',
          title: "Excluir registro",
          text: 'Deseja excluir este registro? Esta ação é permanente.',
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          confirmButtonColor: 'red',
          cancelButtonColor: 'blue',
          cancelButtonText: 'Cancelar'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.service.patch('coletas/' + val.id + '/data-cancelamento', {}).subscribe(() => this.crudTable._pesquisar())
            Swal.fire({
              title: 'Deletado com sucesso',
              text: 'Esse registro foi deletado com sucesso',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            })
          }
        })
      },
      permission: (val: ColetasEntity) => {
        return (val.status == 'ABERTO' || !val.status) && !this.session.checkPerfil(['GALPAO', 'PARCEIRO']);
      }
    },
    {
      name: 'Verificar requisítos MTR',
      action: (row: ColetasEntity) => {
        if (!row.id) return;
        this.verifyMTRColeta(row.id)
      },
      permission: true
    }
  ]

  constructor(
    public service: ColetasService,
    public session: SessionService,
    public router: Router,
    private createTable: CdkDynamicTableService,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.mapEntityPendentes.addActions(<InterfaceCustomActions[]>[
      {
        name: 'excluir',
        action: () => {
        },
        permission: false
      },
      {
        name: 'editar',
        action: () => {
        },
        permission: false
      },
      {
        name: 'Criar rota para esse dia',
        action: (val: ColetaPendenteEntity) => {
          this.router.navigate(['/user/galpao/rotas/novo_pendente'], {queryParams: {data: val.dataColeta}}).then()
        }
      }
    ])
    this.mapEntityCanceladas.configEntityTable.where = {isCancelada: true}
    this.mapEntityCanceladas.addActions([
      {
        name: 'editar', action: () => {
        }, permission: false
      },
      {
        name: 'excluir', action: () => {
        }, permission: false
      },
    ])

    this.searchFilter('', 'cliente')
    this.searchFilter('', 'motorista')
    this.searchFilter('', 'tipoResiduo')

    this.tableMTR = this.createTable.createByEntity(new MtrEntity())
    this.tableColetas = this.createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'coletas',
        params: {isCancelada: false}
      },
      pagination: {
        sort: ['id,desc']
      },
      columns: [
        {
          name: 'idRota',
          headerName: 'ID rota',
          resource: (cel: RotasEntity, row: ColetasEntity) => row.rota?.id || '--',
          sortAttrKey: 'rota.id'
        },
        {
          name: 'dataRota',
          headerName: 'Data coleta',
          resource: (cel: RotasEntity, row: ColetasEntity) => !!row.rota?.dataRota ? row.rota.dataRota.split('-').reverse().join('/') : '--',
          sortAttrKey: 'rota.dataRota'
        },
        {name: 'cliente', headerName: 'Cliente', resource: (cel: ClientesEntity) => !!cel?.nome ? cel.nome : '--'},
        {
          name: 'rotaMotorista',
          headerName: 'Motorista/Catador',
          resource: (cel: any, row: ColetasEntity) => !!row?.rota?.motorista?.nome ? row.rota.motorista.nome : '--'
        },
        {
          name: 'rotaGalpao',
          headerName: 'Transportador',
          resource: (cel: any, row: ColetasEntity) => !!row?.rota?.galpao?.nome ? row.rota.galpao.nome : '--'
        },
        {name: 'codigoMtr', headerName: 'N° MTR'},
        {name: 'tiposResiduo', headerName: 'Tipo de resíduo'},
        {name: 'status', headerName: 'Status'},
      ],
      actions: {
        editar: {
          dbClick: true,
          name: 'Editar',
          action: (val: ColetasEntity) => {
            this.router.navigate(['user/operacional/coletas/coletas/' + val.id]).then()
          },
          permission: this.session.checkProfile(['SINDICO', 'CONSELHO', 'USUARIO_CONSULTA']) || this.session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'PARCEIRO'])
        },
        excluir: {
          name: 'Excluir',
          action: (val: ColetasEntity) => {
            Swal.fire({
              icon: 'warning',
              title: "Excluir registro",
              text: 'Deseja excluir este registro? Esta ação é permanente.',
              showCancelButton: true,
              confirmButtonText: 'Excluir',
              confirmButtonColor: 'red',
              cancelButtonColor: 'blue',
              cancelButtonText: 'Cancelar'
            }).then(confirm => {
              if (confirm.isConfirmed) {
                this.service.patch('coletas/' + val.id + '/data-cancelamento', {}).subscribe(() => this.crudTable._pesquisar())
                Swal.fire({
                  title: 'Deletado com sucesso',
                  text: 'Esse registro foi deletado com sucesso',
                  icon: 'success',
                  timer: 2000,
                  showConfirmButton: false
                })
              }
            })
          },
          permission: (val: ColetasEntity) => {
            return (val.status == 'ABERTO' || !val.status) &&
              !this.session.checkPerfil(['GALPAO', 'PARCEIRO']) &&
              !this.session.checkProfile(['USUARIO_CONSULTA'])
          }
        },
        revisarMTR: {
          name: 'Verificar requisítos MTR',
          action: (row: ColetasEntity) => {
            if (!row.id) return;
            this.verifyMTRColeta(row.id)
          },
          permission: (val: ColetasEntity) => {
            return !this.session.checkProfile(['USUARIO_CONSULTA'])
          }
        }
      },
      sort: ['rota.dataRota', 'cliente', 'dataRota'],
      saveQueryParams: {
        save: true,
        encrypt: false,
        group: 'coletas2'
      },
      filters: {
        group: 'coletas2',
        reactive: false,
        filters: {
          dataInicial: {},
          dataFinal: {},
          clienteId: {},
          motoristaId: {},
          codigoMtr: {},
          tipoResiduoId: {},
        }
      }
    })
    // this.tableMTR.find();

    if (session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])) {
      this.tableColetas.controls.filters.delete('cliente')
    }

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tableMTR.destroy();
    this.tableColetas.destroy();
  }

  searchFilter(value: string, filter: 'cliente' | 'motorista' | 'tipoResiduo') {
    if (filter == 'cliente') {
      this.http.get('clientes/nomes', {params: {nome: value || '' }}).subscribe(resp => {
        this.listClientes = resp.data || [];
      })
    } else if (filter == 'motorista') {
      this.http.get('motoristas', {params: {nome: value || '' }}).subscribe(resp => {
        this.listMotorista = resp.data || [];
      })
    } else {
      this.http.get('tipo-lixo/nomes', {params: {nome: value || '' }}).subscribe(resp => {
        this.listResiduos = resp.data || [];
      })
    }
  }

  public selectContainer: { id: string | number, mtr: boolean, peso: string }[] = [];
  public contSelect: (number | string)[] = [];
  public mtrSelect: (number | string)[] = [];

  private verifyMTRColeta(coletaId: string | number) {
    this.http.get('/mtr/validate/coleta/' + coletaId).subscribe(
      (resp: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Dados do MTR',
          text: resp.data
        }).then()
      }
    )
  }

  resourceTableMtr(item: any, column: CdkDgTable.column) {
    debugger
    if (!(<string>(typeof column == 'string' ? column : column.name) in item)) return '--'

    if (typeof column == 'string') {
      return item[column] || '--';
    }

    if (!!column?.resource) {
      // @ts-ignore
      return column.resource(item[column?.name || ''], item)
    }

    return '--'
  }

  showListTextOnTable(residuos: string[]) {
    return residuos
      .filter(item => !!item)
      .map(item => {
        if (item.length > 40) {
          return `<p class="m-0 text-muted fs-10 w-100">${item.substring(0, 35)}...</p>`
        }

        return `<p class="m-0 text-muted fs-10 w-100">${item}</p>`
      })[0] || '--';
  }

  showAllResiduos(residuos: string[]) {
    const htmlSwal = residuos.map(item => '<p class="m-0 text-muted fs-12 w-100">'+item+'</p>').join('');

    Swal.fire({
      title: 'Tipos de resíduos',
      html: `${htmlSwal}`
    }).then()
  }

  getCliente(val?: string) {

  }
}
