import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientesEntity} from "../clientes.entity";
import {ClientesService} from "../service/clientes.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import {SessionService} from "../../../../../core/services/session-service/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {crudDispatchEvent, InterfaceCustomActions} from "@datagrupo/dg-crud";
import Swal from "sweetalert2";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../environments/environment";
import {HttpService} from "../../../../../services/http-service/http.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ParceiroEntity} from "../../../parceiro/parceiro.entity";
import {formataData} from "../../../../../helpers/helpers";
import {ThisReceiver} from "@angular/compiler";


function defaultFunctionTdClass(cel: any, row: any): string {
  if (!row.ativo) return 'td-inative'
  return ''
}

@Component({
  selector: 'app-clientes-main',
  templateUrl: './clientes-main.component.html',
  styleUrls: ['./clientes-main.component.scss']
})
export class ClientesMainComponent implements OnInit, OnDestroy {

  // public mapEntity = new ClientesEntity();

  public tableClientes: InterfaceCustomActions[] = [
    {
      name: 'Ativar',
      action: (val: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            let data = {
              id: val.id,
              ativo: true,
            }
            this.service.patch('clientes/ativo', data).subscribe(
              () => {
                crudDispatchEvent('clienteTable')
              }
            )
          }
        });
      },
      permission: (val: any)  => {
        return val.ativo == false ? true : false
      }
    },
    {
      name: 'Desativar',
      action: (val: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(confirm => {
            if (confirm.isConfirmed) {
              let data = {
                id: val.id,
                ativo: false,
              }
              this.service.patch('clientes/ativo', data).subscribe(
                () => {
                  crudDispatchEvent('clienteTable')
                }
              )
            }
        });
    },
      permission: (val: any)  => {
        return val.ativo == true ? true : false
      }

    },
    {
      name: 'Visualizar',
      action: (val: any) => {

      },
      permission: false
    },
    {
      name: 'excluir',
      action: (val: any) => {
      },
      permission: false
    }];

  public table: CdkDynamicTable.tableClass;
  public formFilters = new FormGroup({
    clienteId: new FormControl(''),
    parceiroId: new FormControl(''),
    possuiSindico: new FormControl(''),
    ativos: new FormControl(''),
  })

  constructor(
    public service: ClientesService,
    private files: FilesManageService,
    public session: SessionService,
    private route: ActivatedRoute,
    private createTable: CdkDynamicTableService,
    private router: Router,
    private http: HttpService,
  ) {
    // if (!session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])) {
    //   this.mapEntity.removeFilters(['parceiro'])
    // }
    this.table = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: 'clientes'
      },
      columns: [
        { name: 'nome', headerName: 'nome', tdClass: defaultFunctionTdClass},
        { name: 'cnpj', headerName: 'cnpj', tdClass: defaultFunctionTdClass, mask: 'cnpj'},
        { name: 'dataInclusao', headerName: 'dataInclusao', tdClass: defaultFunctionTdClass, resource: val => !!val ? formataData(val) : '--'},
        { name: 'parceiro', headerName: 'parceiro', resource: cel => cel?.nome || '	Não Possui', tdClass: defaultFunctionTdClass},
        { name: 'condominioIndicador', headerName: 'condominioIndicador', resource: cel => cel?.nome || '	Não Possui', tdClass: defaultFunctionTdClass},
      ],
      sort: ['nome', 'cnpj', 'dataInclusao', 'condominioIndicador'],
      actions: {
        editar: {
          name: 'Editar',
          dbClick: true,
          action: row => {
            this.router.navigate(['/user/clientes/clientes/', row?.id]).then();
          }
        },
        excluir: {
          name: 'Excluir',
          action: row => {
            Swal.fire({
              icon: 'warning',
              title: 'Excluir registro',
              text: 'Deseja excluir este registro? Esta ação é permanente.',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              confirmButtonText: 'Excluir'
            }).then(conf => {
              if (conf.isConfirmed) {
                this.http.delete('clientes/'+row.id).subscribe(resp => {
                  this.table.find()
                  Swal.fire({
                    icon: 'success',
                    title: 'Registro excluído',
                    timer: 100,
                    confirmButtonText: 'ok'
                  }).then()
                })
              }
            })
          },
          permission: (val: any)  => {
            return (this.session.checkPerfil(['ADMINISTRATIVO', "ADMINISTRADOR"]) && val?.ativo)
          }
        },
        ativar: {
          name: 'Ativar',
          action: (val: any) => {
            Swal.fire({
              icon: 'question',
              title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
              showCancelButton: true,
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim'
            }).then(confirm => {
              if (confirm.isConfirmed) {
                let data = {
                  id: val.id,
                  ativo: true,
                }
                this.service.patch('clientes/ativo', data).subscribe(
                  () => {
                    this.table.find()
                  }
                )
              }
            });
          },
          permission: (val: any)  => {
            return this.session.checkPerfil(['ADMINISTRATIVO', "ADMINISTRADOR", "PARCEIRO"]) && !val.ativo
          }
        },
        desativar: {
          name: 'Desativar',
          action: (val: any) => {
            Swal.fire({
              icon: 'question',
              title: 'Você deseja ' + (val.ativo ? 'desativar' : 'ativar') + ' o cliente?',
              showCancelButton: true,
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim'
            }).then(confirm => {
              if (confirm.isConfirmed) {
                let data = {
                  id: val.id,
                  ativo: false,
                }
                this.service.patch('clientes/ativo', data).subscribe(
                  () => {
                    this.table.find()
                  }
                )
              }
            });
          },
          permission: (val: any)  => {
            return (this.session.checkPerfil(['ADMINISTRATIVO', "ADMINISTRADOR"]) && val.ativo)
          }

        },
      },
      filters: {
        group: 'clientes',
        reactive: false,
        filters: {
          clienteId: {},
          parceiroId: {},
          possuiSindico: {},
          ativos: {},
        }
      },
      saveQueryParams: {
        group: 'clientes',
        save: true,
      }
    })

    this.searchFilter('', 'cliente')
    this.searchFilter('', 'parceiro')
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.table?.destroy();
  }

  baixarPlanilha() {
    const paramsRoot = !!this.route.snapshot.queryParams['filters']
      ? JSON.parse(this.route.snapshot.queryParams['filters'])['clienteTable']
      : {};

    const params: {[key: string]: any} = {}

    if ('nome' in paramsRoot) {
      if (typeof paramsRoot['nome'] == 'string') {
        params['nomeCliente'] = paramsRoot['nome'];
      } else if (!!paramsRoot['nome']?.id) {
        params['clienteId'] = paramsRoot['nome'].id;
      }
    }

    if ('parceiro' in paramsRoot) {
      if (typeof paramsRoot['parceiro'] == 'string') {
        params['nomeParceiro'] = paramsRoot['parceiro'];
      } else if (!!paramsRoot['parceiro']?.id) {
        params['parceiroId'] = paramsRoot['parceiro'].id;
      }
    }

    if ('ativos' in paramsRoot) {
      params['ativos'] = paramsRoot['ativos'] == 'Sim'
    }

    if ('possuiSindico' in paramsRoot) {
      params['possuiSindico'] = paramsRoot['possuiSindico'] == 'Sim'
    }

    console.log('params', params)

    this.service.get('clientes/planilha', { params }).subscribe(
      resp => {
        this.files.downloadBase64(resp.data, 'Planilha de clientes.xlsx')
      }
    )
  }

  listClientes: Partial<ClientesEntity>[] = [];
  listParceiros: Partial<ParceiroEntity>[] = [];

  searchFilter(value: string, filter: 'cliente' | 'parceiro') {
    if (filter == 'cliente') {
      this.http.get('clientes/nomes', {params: {nome: value || '' }}).subscribe(resp => {
        this.listClientes = resp.data || [];
      });
    } else if (filter == 'parceiro') {
      this.http.get('parceiros/nomes', {params: {nome: value || '' }}).subscribe(resp => {
        this.listParceiros = resp.data || [];
      });
    }
  }
}
