import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientesEntity} from "../../clientes.entity";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {TipoUsuarioClienteEnum, TipoUsuarioClienteType} from "../../../../../../tupo-usuario-cliente.enum";
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ParceiroEntity} from "../../../../parceiro/parceiro.entity";
import {GrupoMercadoEntity} from "../../../../tipo-lixo/grupo-merccado.entity";
import {SegmentosOperacionaisEntity} from "../../../../tipo-lixo/segmentos-operacionais.entity";
import {SegmentoMercadoMercadoEntity} from "../../../../tipo-lixo/segmento-mercado.entity";
import {HttpService} from "../../../../../../services/http-service/http.service";
import {CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA} from "../../../../../../core/config/endpoint-list";
import {defaultCallBackErrorGetCep, defaultCallBackSuccessGetCep} from "../../../../../../helpers/helpers";
import {HttpHelpersService} from "../../../../../../services/http-helpers/http-helpers.service";
import {InputHelpers} from "../../../../../../helpers/input.helpers";
import {
  DefaultAutocompleteInputComponent
} from "../../../../../../shared/custom-autocomplete/default-autocomplete-input/default-autocomplete-input.component";

@Component({
  selector: 'subpage-cliente-sub-page-informacoes',
  templateUrl: './cliente-sub-page-informacoes.component.html',
  styleUrls: ['./cliente-sub-page-informacoes.component.scss']
})
export class ClienteSubPageInformacoesComponent implements OnInit, OnDestroy {
  @Input('entity') entity!: ClientesEntity;

  @ViewChild('autocompleteSegmentosOperacionais') autocompleteSegmentosOperacionais!: DefaultAutocompleteInputComponent;

  public checkedDiasColetas = false;
  public error: boolean = false;
  public linkMorador: string = '';
  public tableContatos?: CdkDynamicTable.tableClass;

  public listParceiro: ParceiroEntity[] = [];
  public listGeradorIndicador: ClientesEntity[] = [];
  public listSegmentosOperacionais: SegmentosOperacionaisEntity[] = []
  public listSegmentosMercado: any[] = []
  public listGruposMercado: [] = []

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    tipoCadastro: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    interesseCliente: new FormControl('', [Validators.required]),
    condominioIndicador: new FormControl({value: '', disabled: false}),
    parceiros: new FormControl({value: [], disabled: false}),

    grupoMercado: new FormControl([], [Validators.required]),
    segmentoMercado: new FormControl({disabled: true, value: []}, [Validators.required]),
    segmentosOperacionais: new FormControl({disabled: true, value: []}, [Validators.required]),
  })

  formEndereco = new FormGroup({
    id: new FormControl(''),
    cep: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl(''),
    logradouro: new FormControl({value: '', disabled: true}, [Validators.required]),
    bairro: new FormControl({value: '', disabled: true}, [Validators.required]),
    cidade: new FormControl({value: '', disabled: true}, [Validators.required]),
    estado: new FormControl({value: '', disabled: true}, [Validators.required]),
  })

  formDadosColeta = new FormGroup({
    qtdApartamentos: new FormControl('', [Validators.required]),
    qtdDeMoradores: new FormControl(''),
    estimativaColetaMensal: new FormControl(''),
  })

  constructor(
    private createTable: CdkDynamicTableService,
    public session: SessionService,
    private http: HttpService,
    public httpHelper: HttpHelpersService,
  ) {
    if (this.session.checkPerfil(['ADMINISTRATIVO', 'ADMINISTRADOR', 'EMPRESA', 'CONDOMINIO', 'CASA', 'EVENTO', 'PARCEIRO', 'GALPAO'])) {
      this.http.get('grupos-mercado', {params: {unpaged: true}}).subscribe((resp) => {
        //@ts-ignore
        this.listGruposMercado = resp.data
      })
    }
    // if (!session.checkPerfil('GALPAO')) {
    //   http.get('parceiros', {params: {unpaged: true}}).subscribe(
    //     resp => this.listParceiro = resp.data
    //   )
    // }

    this.form.controls['grupoMercado'].valueChanges.subscribe(val => {
      if (Array.isArray(val)) {
        if (val.length > 0) {
          this.form.controls['segmentoMercado'].enable();
          this.getSegmentoMercado();
          if ((this.form.value?.segmentoMercado || []).length > 0) {
            this.form.controls['segmentosOperacionais'].enable();
          }
          return;
        }
      }

      this.form.controls['segmentosOperacionais'].disable();
      this.form.controls['segmentoMercado'].disable();
    })

    this.form.controls['segmentoMercado'].valueChanges.subscribe(val => {
      if ((this.form.value?.grupoMercado || []).length > 0) {
        if (Array.isArray(val)) {
          if (val.length > 0) {
            this.form.controls['segmentosOperacionais'].enable();
            this.getSegmentoOperacional();
            return;
          }
        }
      }

      this.form.controls['segmentosOperacionais'].disable();
    })

    this.form.controls['tipoCadastro'].valueChanges.subscribe(value => {
      if (value == 'CASA') {
        this.form.controls['cnpj'].disable()
        this.formDadosColeta.controls['qtdApartamentos'].disable()
      } else {
        this.entity.id ? this.form.controls['cnpj'].disable() : this.form.controls['cnpj'].enable()
        this.formDadosColeta.controls['qtdApartamentos'].enable()
      }
    })

    // TODO remover isso, usar directive
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['estimativaColetaMensal'])
    )
  }

  ngOnInit(): void {
    // this.linkMorador = window.location.origin + '/cadastro-usuario/' + this.entity.id;
    //
    // this.form.patchValue({
    //   ...this.entity,
    //   parceiros: this.entity?.parceiros ? this.entity?.parceiros.map(item => item.id) : [],
    // });
    // console.log('this.entity', this.entity);

    if (!this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      // this.form.controls['galpao']?.disable()
      // this.form.controls['tipoCadastro'].disable()

      if (!this.checkPermission()) {
        this.form.controls['nome'].disable()
        this.form.controls['cnpj'].disable()
        this.form.controls['telefone'].disable()
        this.form.controls['interesseCliente'].disable()
        this.form.controls['parceiros'].disable()
        this.form.controls['condominioIndicador'].disable()
        this.form.controls['cep'].disable()
        this.form.controls['numero'].disable()
        this.form.controls['complemento'].disable()
        this.form.controls['qtdApartamentos'].disable()
        this.form.controls['qtdDeMoradores'].disable()
        this.form.controls['exclusivo'].disable()
        this.form.controls['estimativaColetaMensal'].disable()
      }
    }

    // this.autocompleteHelpers.preselectedGrupoMercado()
  }

  initcreate() {
    if (!this.session.checkPerfil('GALPAO')) {
      this.getParceiro();
    }

    if (this.session.checkPerfil('GALPAO')) {
      if (!!this.entity?.id) {
        if (this.entity?.exclusivo) {
          if (this.entity?.galpao?.id != this.session.user?.galpao_id) {
            this.form.controls['grupoMercado'].disable()
          }
        } else {
          this.form.controls['grupoMercado'].disable()
        }
      }
    }

    // console.log(!(this.session.checkPerfil('GALPAO') && !this.entity?.id))
    // console.log(this.session.checkPerfil('GALPAO'))
    // console.log(!this.entity?.id)
    // debugger
    // if (!(this.session.checkPerfil('GALPAO') && !this.entity?.id)) {
    //   this.form.controls['grupoMercado'].disable()
    // } else if (!!this.entity?.id && this.session.checkPerfil('GALPAO')) {
    //   if (!this.entity?.exclusivo) {
    //     this.form.controls['grupoMercado'].disable()
    //   }
    // }
    this.getGeradorIndicador()
  }

  initUpdate() {
    if (this.entity?.id) {
      this.linkMorador = window.location.origin + '/cadastro-usuario/' + this.entity?.id;
    }

    if (!!this.entity?.id && !this.tableContatos) {
      this.tableContatos = this.createTable.create('array', {
        pagination: {
          size: 5
        },
        columns: [
          {name: 'nome', headerName: 'Nome'},
          {name: 'email', headerName: 'Email'},
          {name: 'telefone', headerName: 'Telefone'},
          {
            name: 'tipoUsuario', headerName: 'Tipo usuário', resource: (val, row) => {
              if (!row?.tipoUsuario) return '--';
              return TipoUsuarioClienteEnum[<TipoUsuarioClienteType>row.tipoUsuario] || '--';
            }
          },
        ]
      });
      this.tableContatos.controls.data.set(this.entity?.contatos || [])
      this.tableContatos.find(false);
    }

    const {segmentosOperacionais, segmentoMercado, grupoMercado} = this.extractEntityGrupoMercado();

    this.form.patchValue({
      ...this.entity,
      parceiros: this.entity?.parceiros ? this.entity?.parceiros.map(item => item.id) : [],
      condominioIndicador: this.entity?.condominioIndicador ? this.entity?.condominioIndicador?.id : '',
      grupoMercado,
      segmentoMercado,
      segmentosOperacionais,
    });

    if (this.entity?.parceiros) {
      this.listParceiro = this.entity.parceiros;
    }
    if (!this.session.checkPerfil('GALPAO')) {
      this.getParceiro()
    }

    if (this.entity?.condominioIndicador) {
      this.listGeradorIndicador = [this.entity.condominioIndicador];
    }
    this.getGeradorIndicador()

    this.formEndereco.patchValue(this.entity.enderecos[0], { emitEvent: false })
    this.formDadosColeta.patchValue({
      ...this.entity,
      ...this.entity.predio
    })

    this.observableGetCep();
  }

  verifyBeforeSave() {
    if (this.form.invalid || this.formEndereco.invalid || this.formDadosColeta.invalid) {
      this.form.markAllAsTouched();
      this.formEndereco.markAllAsTouched();
      this.formDadosColeta.markAllAsTouched();
      this.error = true;
      return false;
    }

    this.error = false;
    return true;
  }

  getData() {
    const {
      condominioIndicador, parceiros, cnpj, tipoCadastro, ...form
    } = this.form.getRawValue();

    const {qtdApartamentos, qtdDeMoradores, estimativaColetaMensal} = this.formDadosColeta.getRawValue();
    const formEnderecos = this.formEndereco.getRawValue();

    const {grupoMercado, segmentoMercado, segmentosOperacionais} = this.getGrupoMercadoBySave();

    return {
      ...form,
      parceiros: parceiros.map((item: string | number) => ({id: item})),
      condominioIndicador: !!condominioIndicador ? {id: condominioIndicador} : null,
      grupoMercado,
      segmentoMercado,
      segmentosOperacionais,
      estimativaColetaMensal: InputHelpers.ajusteMoneyBrToEua(estimativaColetaMensal),
      cnpj: tipoCadastro != 'CASA' ? cnpj : '',
      tipoCadastro,
      predio: {
        ...this.entity.predio,
        qtdApartamentos: tipoCadastro != 'CASA' ? qtdApartamentos : 1,
        qtdDeMoradores: qtdDeMoradores
      },
      enderecos: [formEnderecos]
    }
  }

  ngOnDestroy(): void {
    this.tableContatos?.destroy()
  }

  ajustePesoTotal(val?: string | number) {
    if (!val && (val !== 0)) return "--";
    if (Number(val) == NaN) return "--";

    return Number(val).toLocaleString('BRL');
  }

  /**
   * serve para verificar a possibilidade de edição dos dados na maior parte da tela
   * @param aceptGalpao
   */
  checkPermission(aceptGalpao: boolean = false) {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
      return true;
    }

    if (!this.entity?.id) return true

    if (this.session.checkPerfil('PARCEIRO')) {
      return !!this.entity?.isCreated;
    }

    if (this.session.checkPerfil('GALPAO')) {
      return !!this.entity?.exclusivo;
    }

    if (aceptGalpao) {
      return this.session.checkPerfil('GALPAO');
    }

    return false;
  }


  //--------------------------------------
  // segmentos de mercado e sua estrutura de dados
  //--------------------------------------

  private extractEntityGrupoMercado():
    { grupoMercado: (string | number)[], segmentoMercado: (string | number)[], segmentosOperacionais: (string | number)[] } {
    const grupoMercado: (string | number)[] = [];
    const segmentoMercado: (string | number)[] = [];
    const segmentosOperacionais: (string | number)[] = [];

    const listSegmentosOperacionais = this.entity.segmentosOperacionais || [];

    listSegmentosOperacionais.map(segmentoOpracional => {
      if (!!segmentoOpracional?.id) {
        if (!segmentosOperacionais.find(item => item == segmentoOpracional?.id)) {
          segmentosOperacionais.push(segmentoOpracional?.id);
        }
      }

      if (!!segmentoOpracional?.segmentoMercado?.id) {
        if (!segmentoMercado.find(item => item == segmentoOpracional?.segmentoMercado?.id)) {
          segmentoMercado.push(segmentoOpracional?.segmentoMercado?.id)

          if (!!segmentoOpracional?.segmentoMercado?.grupoMercado?.id) {
            if (!grupoMercado.find(item => item == segmentoOpracional?.segmentoMercado?.grupoMercado?.id)) {
              grupoMercado.push(segmentoOpracional?.segmentoMercado?.grupoMercado?.id)
            }
          }
        }
      }
    })

    return {grupoMercado, segmentoMercado, segmentosOperacionais}
  }

  private getGrupoMercadoBySave():
    { grupoMercado: any[], segmentoMercado: any[], segmentosOperacionais: any[] } {
    const grupoMercado: any[] = [];
    const segmentoMercado: any[] = [];
    const segmentosOperacionais: SegmentosOperacionaisEntity[] = <SegmentosOperacionaisEntity[]>this.autocompleteSegmentosOperacionais.getSelected() || [];

    segmentosOperacionais.map(item => {
      if (item?.segmentoMercado?.id) {
        if (!segmentoMercado.includes(item?.segmentoMercado?.id)) {
          segmentoMercado.push(item.segmentoMercado)

          if (item?.segmentoMercado?.grupoMercado?.id) {
            if (!grupoMercado.includes(item?.segmentoMercado?.grupoMercado?.id)) {
              grupoMercado.push(item.segmentoMercado?.grupoMercado)
            }
          }
        }
      }
    })

    return {grupoMercado, segmentoMercado, segmentosOperacionais}
  }

  getSegmentoMercado() {
    const gruposMercadoList = this.form.value?.grupoMercado || [];

    this.http.get('segmentos-mercado', {params: {unpaged: true, gruposMercadoList}}).subscribe((resp) => {
      this.listSegmentosMercado = resp.data
    })
  }

  getSegmentoOperacional() {
    const segmentosMercadoId = this.form.value?.segmentoMercado || [];

    this.http.get('segmentos-operacionais', {params: {unpaged: true, segmentosMercadoId}}).subscribe((resp) => {
      this.listSegmentosOperacionais = resp.data
    })
  }

  addCepManual() {
    this.formEndereco.controls['logradouro'].enable();
    this.formEndereco.controls['bairro'].enable();
    this.formEndereco.controls['cidade'].enable();
    this.formEndereco.controls['estado'].enable();
  }

  observableGetCep() {
    this.formEndereco.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        this.httpHelper.getCep(cep,
          data => {
            this.formEndereco.patchValue({
              "logradouro": data.logradouro,
              "bairro": data.bairro,
              "cidade": data.localidade,
              "estado": data.uf
            });
            defaultCallBackSuccessGetCep(this.form)
          },
          () => defaultCallBackErrorGetCep(this.form)
        )

      }
    )
  }


  //--------------------------------------
  // select de quantidades de quartos/apartamentos
  //--------------------------------------

  showQtdQuartos(): boolean {
    const {tipoCadastro} = this.form.getRawValue()

    return tipoCadastro == 'CONDOMINIO' || tipoCadastro == 'CASA'
  }

  select_qtdQuartosPorApartamento(valor: string) {
    const index = this.entity.predio.qtdQuartosPorApartamento.findIndex(arrayDia => arrayDia == valor);

    if (index == -1) {
      this.entity.predio.qtdQuartosPorApartamento.push(valor);
      return;
    }

    this.entity.predio.qtdQuartosPorApartamento.splice(index, 1);
  }

  verify_qtdQuartosPorApartamento(valor: string): boolean {
    const index = this.entity?.predio?.qtdQuartosPorApartamento.findIndex(arrayDia => arrayDia == valor);

    return index != -1;
  }

  recalcularEstimativas() {
    const {qtdApartamentos, qtdDeMoradores, tipoCadastro} = this.form.getRawValue()
    const qtdQuartosPorApartamento = this.entity.predio.qtdQuartosPorApartamento
    this.http.get(
      CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA,
      {
        params: {
          qtdApartamentos: tipoCadastro != 'CASA' ? qtdApartamentos : 1,
          qtdDeMoradores: qtdDeMoradores,
          qtdQuartosPorApartamentoEnumList: qtdQuartosPorApartamento
        }
      }).subscribe(resp => {
      if (resp) {
        //@ts-ignore
        this.entity?.estimativas = resp.data
      }
    })
  }

  //--------------------------------------
  // Buscas por listas de seleção
  //--------------------------------------

  getParceiro(nome?: string) {
    this.http.get('parceiros/nomes', {params: {nome: nome || ''}}).subscribe(
      resp => this.listParceiro = resp.data
    )
  }

  getGeradorIndicador(nome?: string) {
    this.http.get('clientes/nomes', {params: {nome: nome || ''}}).subscribe(
      resp => {
        this.listGeradorIndicador = resp.data
      }
    )
  }
}
