import { Injectable } from '@angular/core';
import {AbstractActionsTableService, InterfaceCustomActions} from "@datagrupo/dg-crud";
import {FilesManageService} from "../../../../../../services/files-manage/files-manage.service";
import {ColetaArquivosEntity} from "../../coleta-arquivos.entity";
import {ColetaArquivosService} from "../coleta-arquivos.service";
import Swal from "sweetalert2";
import {SessionService} from "../../../../../../core/services/session-service/session.service";

@Injectable({
  providedIn: 'root'
})
export class ColetasArquivosActionTableService extends AbstractActionsTableService{

  constructor(private files: FilesManageService, private service: ColetaArquivosService, private session: SessionService) {
    super();
  }

  override list(): InterfaceCustomActions[] {
    const excluir: InterfaceCustomActions = super.list()[1]
    return [
      {
        name: 'Download',
        action: (arquivo: ColetaArquivosEntity) => this.download(arquivo)
      },
      {
        name: 'Editar',
        action: () => {},
        permission: () => false
      },
      {
        name: 'Alterar Privacidade',
        action: (file: ColetaArquivosEntity) => this.alterarPrivacidade(file),
        permission: () => !this.session.checkProfile('USUARIO_CONSULTA')
      }
    ];
  }

  download(file?: ColetaArquivosEntity) {
    if (!!file?.id) {
      this.service.findOne(file.id, '').subscribe(
        resp => {
          //@ts-ignores
          if (!!resp.data.file && !!resp.data.fileNameOriginal) {
            //@ts-ignores
            this.files.downloadBase64(resp.data.file, resp.data.fileNameOriginal)
          }

        }
      )
    }
  }

  alterarPrivacidade(file: ColetaArquivosEntity) {
    if (!!file?.id) {
      Swal.fire({
        title: 'Arquivo Privado',
        input: 'checkbox',
        inputValue: !!file.privado ? 1 : 0 ,
        inputPlaceholder:
          'Arquivo Privado',
      }).then((valid) => {
        file.privado = !!Number(valid.value)
        this.service.update(file, '').subscribe(
          resp => {
            console.log(resp)
          }
        )
      })
    }
  }
}
