<div>
  <div class="row mt-2" *ngIf="!!tableContatos">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Informações de Contato</h4>
        </div>
        <div class="card-body">
          <table class="w-100 crud-table">
            <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Tipo de usuário</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cont of tableContatos.controls.data.dataSource">
              <td>{{cont?.nome}}</td>
              <td>{{cont?.email}}</td>
              <td>{{cont?.telefone}}</td>
              <td>{{cont?.tipoUsuario}}</td>
            </tr>
            <tr *ngIf="tableContatos.controls.data.dataSource.length == 0">
              <td colspan="100%" class="text-center">Nenhum registro encontrado</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">

          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableContatos"></dg-paginator>
        </div>
      </card>
    </div>
  </div>

  <div
    *ngIf="entity?.id && entity?.info"
    class="row mt-3"
  >
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Informações gerais</h4>
        </div>
        <div class="card-body">
          <div *ngIf="!session.checkPerfil(['GALPAO'])" class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label
                class="form-label"
                *ngIf="form.getRawValue()?.tipoCadastro != 'EMPRESA'"
              >
                Quantidade de usuários:
              </label>
              <label
                class="form-label"
                *ngIf="form.getRawValue()?.tipoCadastro == 'EMPRESA'"
              >
                Quantidade de
                funcionários:
              </label>
              {{entity.info?.quantidadeDeMoradores || ''}}
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Quantidade de coletas:</label>
              {{entity.info?.quantidadeDeColetas || ''}}
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Peso total (ton):</label>
              {{ajustePesoTotal(entity.info?.pontos)}}
            </div>
          </div>


          <div *ngIf="!session.checkPerfil(['GALPAO'])" class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Quantidade de missões:</label>
              {{entity.info?.quantidadeDeMissoes || ''}}
            </div>
            <!--              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">-->
            <!--                <label class="form-label">PONTOS NO RANKING:</label>-->
            <!--                {{entity.info?.quantidadePontosDoRanking || ''}}-->
            <!--              </div>-->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label class="form-label">Meses com a ECOPLAT:</label>
              {{entity.info?.mesesComMinhaColeta || ''}}
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-12">
              <label class="form-label">Link para auto cadastro de usuários</label>
            </div>
            <div class="col-12">
              <a
                target="_blank"
                [href]="linkMorador"
              >
                {{linkMorador}}
              </a>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Dados do Gerador</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="nome" class="form-label">
                  NOME
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  formControlName="nome"
                  class="form-control"
                  id="nome"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="tipoCadastro" class="form-label">
                  TIPO DE GERADOR
                  <small class="fc-red">*</small>
                </label>
                <select
                  id="tipoCadastro"
                  class="form-select"
                  formControlName="tipoCadastro"
                >
                  <option value selected disabled>Selecione</option>
                  <option value="CASA">Casa</option>
                  <option value="CONDOMINIO">Condomínio</option>
                  <option value="EVENTO">Evento</option>
                  <option value="EMPRESA">Empresa</option>
                </select>
              </div>
              <div
                *ngIf="form.getRawValue()?.tipoCadastro != 'CASA'"
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
              >
                <label for="cnpj" class="form-label">
                  CNPJ
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  formControlName="cnpj"
                  class="form-control"
                  id="cnpj"
                  mask="00.000.000/0000-00"
                >
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="telefone" class="form-label">
                  TELEFONE
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  formControlName="telefone"
                  class="form-control"
                  id="telefone"
                  mask="(00) 0000-0000||(00) 00000-0000"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="interesseCliente" class="form-label">
                  INTERESSE PRINCIPAL
                  <span class="fc-red">*</span>
                </label>
                <select
                  class="form-select"
                  id="interesseCliente"
                  formControlName="interesseCliente"
                  aria-label="Selecione um interesse"
                >
                  <!-- <option selected>selecione</option> -->
                  <option value="MELHORAR">
                    Melhorar e conscientizar sobre coleta seletiva
                  </option>
                  <option value="VANTAGENS">
                    Ter vantagens através da Coleta Seletiva
                  </option>
                  <option value="IMPLANTARCOLETA">Implantar a coleta seletiva</option>
                </select>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
              >
                <label class="form-label">UNIDADE INDICADORA</label>
<!--                <input-auto-complete-->
<!--                  [baseValue]="entity?.condominioIndicador"-->
<!--                  propertValue="nome"-->
<!--                  controlName="condominioIndicador"-->
<!--                  urlServer="clientes/nomes"-->
<!--                  (outValue)="autocompleteHelpers.setCondominioIndicador($event)"-->
<!--                  [disabled]="!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])"-->
<!--                ></input-auto-complete>-->
                <default-autocomplete-input
                  formControlName="condominioIndicador"
                  key="id"
                  classes="form-control"
                  label="nome"
                  [data]="listGeradorIndicador"
                  typeFilter="outside"
                  (searchFilterValue)="getGeradorIndicador($event)"
                >
                </default-autocomplete-input>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
              >
                <label class="form-label">Master</label>
                <default-autocomplete-input
                  formControlName="parceiros"
                  key="id"
                  classes="form-control"
                  label="nome"
                  [multiple]="true"
                  [data]="listParceiro"
                  typeFilter="outside"
                  (searchFilterValue)="getParceiro($event)"
                  (close)="getParceiro()"
                >
                </default-autocomplete-input>
              </div>

            </div>

            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Grupo de Mercado <span class="fc-red">*</span></label>
                  <default-autocomplete-input
                    formControlName="grupoMercado"
                    [multiple]="true"
                    key="id"
                    label="nome"
                    [data]="(listGruposMercado || [])"
                  ></default-autocomplete-input>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Segmento de Mercado <span class="fc-red">*</span></label>
                  <default-autocomplete-input
                    formControlName="segmentoMercado"
                    [multiple]="true"
                    key="id"
                    label="nome"
                    [data]="(listSegmentosMercado || [])"
                  ></default-autocomplete-input>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <label class="form-label">Segmento Operacional <span class="fc-red">*</span></label>
                  <default-autocomplete-input
                    #autocompleteSegmentosOperacionais
                    formControlName="segmentosOperacionais"
                    [multiple]="true"
                    key="id"
                    label="nome"
                    [data]="(listSegmentosOperacionais || [])"
                  ></default-autocomplete-input>
                </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>

  <form [formGroup]="formEndereco">
    <div class="row mt-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header w-100">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h4 class="card-title">Endereço</h4>
                </div>
                <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <button
                    *ngIf="checkPermission()"
                    class="btn btn-success btn-sm"
                    (click)="addCepManual()"
                  >
                    Adicionar dados manualmente
                  </button>
                </card-header-buttons>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="cep" class="form-label">
                  CEP
                  <span class="font-color-red">*</span>
                </label>
                <input
                  type="text"
                  formControlName="cep"
                  class="form-control"
                  id="cep"
                  placeholder="00.000-000"
                  mask="00.000-000"
                >
              </div>

              <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                <label for="logradouro" class="form-label">
                  LOGRADOURO
                  <span class="font-color-red">*</span>
                </label>
                <input
                  type="text"
                  formControlName="logradouro"
                  class="form-control"
                  id="logradouro"
                >
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <label for="numero" class="form-label">
                  NUMERO
                  <span class="font-color-red">*</span>
                </label>
                <input
                  type="number"
                  formControlName="numero"
                  class="form-control"
                  id="numero"
                >
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <label for="complemento" class="form-label">
                  COMPLEMENTO
                </label>
                <input
                  type="text"
                  formControlName="complemento"
                  class="form-control"
                  id="complemento"
                >
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <label for="bairro" class="form-label">
                  BAIRRO
                  <span class="font-color-red">*</span>
                </label>
                <input
                  formControlName="bairro"
                  type="text"
                  class="form-control"
                  id="bairro"
                >
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <label for="cidade" class="form-label">
                  CIDADE
                  <span class="font-color-red">*</span>
                </label>
                <input
                  formControlName="cidade"
                  type="text"
                  class="form-control"
                  id="cidade"
                >
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <label for="uf" class="form-label">
                  UF
                  <span class="font-color-red">*</span>
                </label>
                <input
                  formControlName="estado"
                  type="text"
                  class="form-control"
                  id="uf"
                >
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>

  <form [formGroup]="formDadosColeta">
    <div class="row mt-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <card>
          <div class="card-header">
            <h4 class="card-title">Dados da coleta</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                *ngIf="showQtdQuartos()"
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
              >
                <label class="form-label col-xl-12 col-lg-12 col-md-6 col-sm-12 d-inline-flex justify-content-center">
                  QUANTIDADE DE {{form.getRawValue()?.tipoCadastro == 'CASA' ? 'CÔMODOS' : 'QUARTOS POR APARTAMENTO'}}
                  <span class="fc-red">*</span>
                </label>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 d-inline-flex justify-content-center">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="select_qtdQuartosPorApartamento('UM')"
                      [checked]="verify_qtdQuartosPorApartamento('UM')"
                      type="checkbox"
                      id="quantQuartos1"
                    >
                    <label class="form-check-label" for="quantQuartos1">
                      1
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="select_qtdQuartosPorApartamento('DOIS')"
                      type="checkbox"
                      [checked]="verify_qtdQuartosPorApartamento('DOIS')"
                      id="quantQuartos2"
                    >
                    <label class="form-check-label" for="quantQuartos2">
                      2
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="select_qtdQuartosPorApartamento('TRES')"
                      [checked]="verify_qtdQuartosPorApartamento('TRES')"
                      type="checkbox"
                      id="quantQuartos3"
                    />
                    <label class="form-check-label" for="quantQuartos3">
                      3
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="select_qtdQuartosPorApartamento('QUATRO')"
                      [checked]="verify_qtdQuartosPorApartamento('QUATRO')"
                      type="checkbox"
                      id="quantQuartos4"
                    />
                    <label class="form-check-label" for="quantQuartos4">
                      4
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      (click)="select_qtdQuartosPorApartamento('CINCO_OU_MAIS')"
                      [checked]="verify_qtdQuartosPorApartamento('CINCO_OU_MAIS')"
                      type="checkbox"
                      id="quantQuartos5"
                    />
                    <label class="form-check-label" for="quantQuartos5">
                      5+
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                *ngIf="form.getRawValue()?.tipoCadastro != 'CASA'"
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
              >
                <label for="qtdApartamentos" class="form-label">
                  QUANTIDADE DE
                  {{ !showQtdQuartos() ? "SETORES" : "APARTAMENTOS" }}
                  <span class="fc-red">*</span>
                </label>
                <input
                  type="text"
                  mask="0*"
                  formControlName="qtdApartamentos"
                  class="form-control"
                  id="qtdApartamentos"
                >
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                *ngIf="form.getRawValue()?.tipoCadastro != 'EVENTO'"
              >
                <label for="qtdDeMoradores" class="form-label">
                  QUANTIDADE DE
                  {{
                  form.getRawValue()?.tipoCadastro != "EMPRESA"
                    ? "USUÁRIOS"
                    : "FUNCIONÁRIOS"
                  }}
                </label>
                <input
                  type="text"
                  mask="0*"
                  formControlName="qtdDeMoradores"
                  class="form-control"
                  id="qtdDeMoradores"
                >
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12"
                *ngIf="!showQtdQuartos()"
              >
                <label for="qtdDeMoradores" class="form-label">
                  ESTIMATIVA DE COLETA
                  {{
                  form.getRawValue()?.tipoCadastro != "EVENTO"
                    ? "MENSAL"
                    : ""
                  }}
                  (TON)
                </label>
                <input
                  type="text"
                  formControlName="estimativaColetaMensal"
                  class="form-control"
                  id="estimativa_coleta_mensal"
                />
              </div>
            </div>

            <div *ngIf="showQtdQuartos()">
              <div
                class="pt-5 recal-estimativas"
                *ngIf="
                    session.checkPerfil([
                      'CONDOMINIO',
                      'ADMINISTRATIVO',
                      'ADMINISTRADOR'
                    ])
                  "
              >
                <button
                  class="btn btn-success fs-10"
                  (click)="recalcularEstimativas()"
                >
                  recalcular estimativas
                </button>
              </div>

              <hr
                class=" mb-5"
                *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
              >


              <div
                class="row"
                *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
              >
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h5>Estimativa para o cliente</h5>
                  <table class="crud-table">
                    <tbody>
                    <tr>
                      <td>TOTAL DE QUARTOS:</td>
                      <td>
                        {{ entity?.estimativas?.estimativaCliente?.totalQuartos || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL DE RDO GERADO NO CONDOMÍO DIA:</td>
                      <td>
                        {{ entity?.estimativas?.estimativaCliente?.totalRDODia || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL DE RDO GERADO NO CONDOMÍO MÊS:</td>
                      <td>
                        {{ entity?.estimativas?.estimativaCliente?.totalRDOMes || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>VALOR ESTIMADO DOS RECICLAVEIS(KG):</td>
                      <td>
                        {{ entity?.estimativas?.estimativaCliente?.valorEstimadoReciclaveis || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>METROS CÚBICOS GERADOS:</td>
                      <td>
                        {{ entity?.estimativas?.estimativaCliente?.metrosCubicosGerados || 0 }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h5>Estimativa para logística</h5>
                  <table class="crud-table">
                    <tbody>
                    <tr>
                      <td>RESÍDUOS POR QUARTO(KG):</td>
                      <td>
                        {{ entity?.estimativas?.estimativaLogistica?.residuosPorQuarto || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL DE APARTAMENTOS:</td>
                      <td>
                        {{ entity?.estimativas?.estimativaLogistica?.totalApartamentos || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL ESTIMADO DE RECICLÁVEIS(KG):</td>
                      <td>
                        {{ entity?.estimativas?.estimativaLogistica?.totalEstimadoReciclaveis || 0 }}
                      </td>
                    </tr>
                    <tr>
                      <td>TOTAL DE RECICLÁVEIS NO MÊS(KG):</td>
                      <td>
                        {{ entity?.estimativas?.estimativaLogistica?.totalReciclaveisMes || 0 }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </form>
</div>
