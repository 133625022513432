import {InterfaceMenuList} from "../../../layouts/user/components/navigation/navigation.component";
import {InterfMenuList} from "./admin.menuList";

export const CondominioMoradorMenuList: InterfMenuList[] = [
  {
    menuList: [

      {url: '/user', nome: 'Início', icon: 'desktop_windows'},
      {url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart'},
      {url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete'},
      {url: '/user/jornada-educacao-ambiental', nome: 'Jornada Educação Ambiental', icon: 'emoji_objects'},
      {
        url: '/user/selecionar-plano', nome: 'Contratar Coletas', icon: 'local_mall', subMenu: [
          {url: '/user/selecionar-plano', nome: 'Market Place', icon: 'local_mall'},
        ]
      },
      // {
      //   url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory', subMenu: [
      //     {url: '/user/industriaDestino/arquivos-destinador', nome: "Arquivos do destinador", icon: 'emoji_objects'},
      //   ]
      // },
      // { url: '/user/controle-pesagem', nome: 'Controle de Peso Final', icon: 'scale' },
    ]
  }
];

export const CondominioMorador2MenuList: InterfMenuList[] = [
  {
    menuList: [
      {url: '/user', nome: 'Início', icon: 'desktop_windows'},
      {url: '/user/clientes/clientes', nome: "Gerador", icon: 'bar_chart'},
      {url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart'},
      {url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete'},
      {url: '/user/galpao/arquivos', nome: 'Arquivos Do Transportador', icon: 'assignment'},
      {
        url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory', subMenu: [
          {url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory'},
        ]
      },
    ]
  }
]

export const CondominioMenuList: InterfMenuList[] = [
  {
    menuList: [

      {url: '/user', nome: 'Início', icon: 'desktop_windows'},
      {url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart'},
      {url: '/user/clientes/clientes', nome: "Gerador", icon: 'home_work'},
      {url: '/user/galpao/arquivos-motoristas', nome: 'Arquivos De Motorista/Catador', icon: 'assignment'},
      {url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete'},
      {url: '/user/galpao/arquivos', nome: 'Arquivos Do Transportador', icon: 'assignment'},
      {url: '/user/clientes/funcionarios', nome: "Usuários", icon: 'account_circle'},
      {url: '/user/jornada-educacao-ambiental', nome: 'Jornada Educação Ambiental', icon: 'emoji_objects'},

      {
        url: '/user/selecionar-plano', nome: 'Contratar Coletas', icon: 'local_mall', subMenu: [
          {url: '/user/selecionar-plano', nome: 'Market Place', icon: 'local_mall'},
          { url: 'http://easyquote.com.br/login', nome: 'Cotação', icon: 'mail',  },
          {url: '/user/selecionar-plano/programa-logistica-reversa', nome: 'Adote um ponto de coleta', icon: 'emoji_objects'},
        ]
      },
      // {
      //   url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory', subMenu: [
      //     {url: '/user/industriaDestino/arquivos-destinador', nome: "Arquivos do destinador", icon: 'emoji_objects'},
      //   ]
      // },
      // { url: '/user/controle-pesagem', nome: 'Controle de Peso Final', icon: 'scale' },
    ]
  }
];
