import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractInsertEdit,
  CrudDataTableComponent,
  crudDispatchEvent,
  HttpClient,
} from "@datagrupo/dg-crud";
import { ClientesEntity } from "../clientes.entity";
import { ConfigDgCrudService } from "../../../../../core/config/config-dg-crud/config-dg-crud.service";
import { ClientesService } from "../service/clientes.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GalpaoEntity } from "../../../_galpao/galpao/galpao.entity";
import { ArquivosClientesEntity } from "../../_entitys/arquivos-clientes.entity";
import { ContainerClienteEntity } from "../../_entitys/container-cliente.entity";
import { MissoesClientesService } from "../../missoes-clientes/service/missoes-clientes.service";
import { ParceiroEntity } from "../../../parceiro/parceiro.entity";
import { ArquivosClienteModalComponent } from '../subComponents/arquivos-cliente-modal/arquivos-cliente-modal.component';
import Swal from 'sweetalert2';
import { OldModalComponent } from 'src/app/shared/ui/modal/modal/modal.component';
import {
  CLIENTE_ATIVO_INATIVO,
  CLIENTE_COLETAS_RECALCULAR_ESTIMATIVA,
  GALPAO_FIND_FILTER
} from "../../../../../core/config/endpoint-list";
import { HttpHelpersService } from "../../../../../services/http-helpers/http-helpers.service";
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { defaultCallBackErrorGetCep, defaultCallBackSuccessGetCep } from 'src/app/helpers/helpers';
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { environment } from 'src/environments/environment';
import { ParceiroService } from '../../../parceiro/service/parceiro.service';
import { SegmentosOperacionaisEntity } from '../../../tipo-lixo/segmentos-operacionais.entity';
import {GrupoMercadoEntity} from "../../../tipo-lixo/grupo-merccado.entity";
import {SegmentoMercadoMercadoEntity} from "../../../tipo-lixo/segmento-mercado.entity";
import {InputAutocompleteComponent} from "@datagrupo/dg-ng-util";
import {TipoUsuarioClienteEnum, TipoUsuarioClienteType} from "../../../../../tupo-usuario-cliente.enum";
import {HttpService} from "../../../../../services/http-service/http.service";
import {Router} from "@angular/router";
import {ClienteSubPageMtrComponent} from "../sub-pages/cliente-sub-page-mtr/cliente-sub-page-mtr.component";
import {
  ClienteSubPageContratoComponent
} from "../sub-pages/cliente-sub-page-contrato/cliente-sub-page-contrato.component";
import {
  ClienteSubPageInformacoesComponent
} from "../sub-pages/cliente-sub-page-informacoes/cliente-sub-page-informacoes.component";

@Component({
  selector: 'app-clientes-insert-edit',
  templateUrl: './clientes-insert-edit.component.html',
  styleUrls: ['./clientes-insert-edit.component.scss']
})
export class ClientesInsertEditComponent extends AbstractInsertEdit<ClientesEntity> implements OnInit {

  viewPage: 'INFO' | 'MTR' | 'CONTRATO' | 'COLETA' | 'INFO-OLD' = 'INFO';

  @ViewChild('subPageInfo') subPageInfo!: ClienteSubPageInformacoesComponent;
  @ViewChild('subPageMTR') subPageMTR!: ClienteSubPageMtrComponent;
  @ViewChild('subPageContrato') subPageContrato!: ClienteSubPageContratoComponent;

  // public form = new FormGroup({
  //   nome: new FormControl('', [Validators.required]),
  //   cnpj: new FormControl('', [Validators.required]),
  //   tipoCadastro: new FormControl('', [Validators.required]),
  //   telefone: new FormControl('', [Validators.required]),
  //   interesseCliente: new FormControl('', [Validators.required]),
  //
  //   grupoMercado: new FormControl('', [Validators.required]),
  //   segmentoMercado: new FormControl({ disabled: true, value: '' }, [Validators.required]),
  //   segmentosOperacionais: new FormControl({ disabled: true, value: '' }, [Validators.required]),
  //
  //   condominioIndicador: new FormControl({ value: '', disabled: false }),
  //   galpao: new FormControl(''),
  //   parceiros: new FormControl({ value: [], disabled: false }),
  //
  //   cep: new FormControl('', [Validators.required]),
  //   numero: new FormControl('', [Validators.required]),
  //   complemento: new FormControl(''),
  //   logradouro: new FormControl({ value: '', disabled: true }, [Validators.required]),
  //   bairro: new FormControl({ value: '', disabled: true }, [Validators.required]),
  //   cidade: new FormControl({ value: '', disabled: true }, [Validators.required]),
  //   uf: new FormControl({ value: '', disabled: true }, [Validators.required]),
  //
  //   qtdApartamentos: new FormControl('', [Validators.required]),
  //   qtdDeMoradores: new FormControl(''),
  //   estimativaColetaMensal: new FormControl(''),
  // })

  public checkedDiasColetas = false;

  public preSelectedParceiros: ParceiroEntity[] = [];

  public urlAutocompleteParceiro = environment.apiUrl + 'parceiros'
  public listGalpao: GalpaoEntity[] = [];
  public listEstimativaCliente: any[] = [];
  public listEstimativaLogistica: any[] = [];


  public recalcular: boolean = false;
  public contatos: any[] = [];


  constructor(
    public conf: ConfigDgCrudService,
    public service: ClientesService,
    public missaoServic: MissoesClientesService,
    public httpHelper: HttpHelpersService,
    public http: HttpService,
    public session: SessionService,
    public parcService: ParceiroService,
    private router: Router
  ) {
    super(conf, service);
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  initNewEntity(): void {
    this.entity = new ClientesEntity();
    setTimeout(() => this.subPageInfo.initcreate(), 100)

    // TODO essa instrução deve ser passada a subpage-contratos.
    //  ela define que apenas um administrados pode escolher o transportador,
    //  e transportadores ao criar geradores tem sua relação de contrado exclusivas
    // if (!this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
    //   this.form.controls['galpao'].disable()
    //
    //   this.form.patchValue({
    //     galpao: this.session.user.galpao_id,
    //     exclusivo: true
    //   })
    // }
  }

  override afterFetchEntity() {
    this.entity.estimativaColetaMensal = InputHelpers.initInputDecimal(this.entity.estimativaColetaMensal || '') || this.entity.estimativaColetaMensal

    this.crudConfig.subscribeEntityAfterSave = false

    setTimeout(() => this.subPageInfo.initUpdate(), 100)
    this.subPageMTR.setData(this.entity)
    this.subPageContrato.setData(this.entity)

    if (this.entity.galpao) {
      this.listGalpao = [
        ...this.listGalpao,
        this.entity.galpao
      ]
    }

    //@ts-ignore
    this.missaoServic.get('clientes/missoes/' + this.entity.id);
  }

  override beforeSaveEntity(): boolean {
    const resultCheckInfo = !this.subPageInfo.verifyBeforeSave();
    const resultCheckContrato = !this.subPageContrato.verifyBeforeSave();

    if (resultCheckInfo || resultCheckContrato) {
      this.checkedDiasColetas = true;
      return false;
    }

    const formValuesInfo = this.subPageInfo.getData();
    const FormValuesMTR = this.subPageMTR.getData();
    const FormValuesContrato = this.subPageContrato.getData();


    this.entity = {
      ...this.entity,
      ...formValuesInfo,
      ...FormValuesMTR,
      ...FormValuesContrato,
    }

    return true;
  }

  override afterSaveEntity(): void {
    if (this.entity.id && !this.entityId) {
      this.entityId = this.entity.id;
    }

    setTimeout(() => this.subPageInfo.initUpdate(), 100);
    setTimeout(() => this.subPageContrato.init(), 100);

    if (this.entity.tipoCadastro == 'CASA' || this.entity.tipoCadastro == 'CONDOMINIO') {
      this.subPageInfo.recalcularEstimativas();
    }
  }

  ativarDesativarCliente() {
    Swal.fire({
      icon: 'question',
      title: 'Você deseja ' + (this.entity?.ativo ? 'desativar' : 'ativar') + ' o cliente?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then(confirm => {
      if (confirm.isConfirmed) {
        let data = {
          id: this.entityId,
          ativo: !this.entity.ativo
        }
        this.service.patch(CLIENTE_ATIVO_INATIVO, data).subscribe(resp => {
          if (resp) {
            this.entity.ativo = !this.entity.ativo
            Swal.fire({
              icon: 'success',
              title: 'Gerador ' + (this.entity.ativo ? 'ativado' : 'desativado') + ' com sucesso',
              timer: 5000,
              showConfirmButton: false
            })
          }
        })
      }
    })
  }

  checkPerfil() {
    if (this.session.user.perfil == "ADMINISTRADOR" || this.session.user.perfil == "ADMINISTRATIVO" || this.session.user.perfil == 'GALPAO') {
      if (!!this.entity?.id) {
        return true
      }
    }
    return false
  }

  checkPerfilPergunta(perfil: string) {
    if (this.session.user.perfil == 'ADMINISTRADOR' || this.session.user.perfil == 'ADMINISTRATIVO' || this.session.user.perfil == 'GALPAO') {
      if (perfil == this.subPageInfo.form.getRawValue().tipoCadastro) {
        return true
      }
    }
    return this.session.user.perfil == perfil;
  }

  /**
   * serve para verificar a possibilidade de edição dos dados na maior parte da tela
   * @param aceptGalpao
   */
  checkPermission(aceptGalpao: boolean = false) {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
      return true;
    }

    if (!this.entity?.id) return true

    if (this.session.checkPerfil('PARCEIRO')) {
     return !!this.entity?.isCreated;
    }

    if (this.session.checkPerfil('GALPAO')) {
     return !!this.entity?.exclusivo;
    }

    if (aceptGalpao) {
      return this.session.checkPerfil('GALPAO');
    }

    return false;
  }

}
