import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../../../../../core/services/session-service/session.service";
import {CdkDynamicTable, CdkDynamicTableService, DgModalComponent} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../../environments/environment";
import {
  CLIENTE_ATIVO_INATIVO,
  CLIENTE_CONTAINER,
  GALPAO_FIND_FILTER
} from "../../../../../../core/config/endpoint-list";
import {GalpaoEntity} from "../../../../_galpao/galpao/galpao.entity";
import {ContainerClienteEntity} from "../../../_entitys/container-cliente.entity";
import {ContainerEntity} from "../../../../container/container.entity";
import {
  ModalClienteRelacaoContratoComponent
} from "../../subComponents/modal-cliente-relacao-contrato/modal-cliente-relacao-contrato.component";
import {HttpService} from "../../../../../../services/http-service/http.service";
import Swal from "sweetalert2";
import {ClientesEntity} from "../../clientes.entity";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'subPage-cliente-sub-page-contrato',
  templateUrl: './cliente-sub-page-contrato.component.html',
  styleUrls: ['./cliente-sub-page-contrato.component.scss']
})
export class ClienteSubPageContratoComponent implements OnInit, OnDestroy {

  public error: boolean = false;

  @Input('clienteId') clienteId: string | number | undefined;
  @Input('entity') entity?: ClientesEntity;

  @ViewChild('modalRelacaoContrato') modalRelacaoContrato!: ModalClienteRelacaoContratoComponent;

  tableRelacaoContrato!: CdkDynamicTable.tableClass
  tableMissoesAceitas!: CdkDynamicTable.tableClass

  public checkedDiasColetas = false;
  public listGalpao: GalpaoEntity[] = [];

  filterInputs = {
    relacaoContrato: {
      isAtivo: true
    }
  }

  form = new FormGroup({
    galpao: new FormControl(''),
    exclusivo: new FormControl(false),
  })

  constructor(
    public session: SessionService,
    private createTable: CdkDynamicTableService,
    private http: HttpService
  ) {
    if (!this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO'])) {
      this.form.controls['galpao'].disable()

      if (this.session.checkPerfil(['GALPAO']) && !this.entity?.id) {
        this.form.patchValue({
          galpao: this.session.user.galpao_id,
          exclusivo: true
        })
      }
    }
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.tableRelacaoContrato?.destroy()
    this.tableMissoesAceitas?.destroy()
  }

  init() {
    if (!!this.clienteId && !this.tableRelacaoContrato) {
      this.tableRelacaoContrato = this.createTable.create('request', {
        apiData: {
          path: environment.apiUrl,
          context: CLIENTE_CONTAINER,
          params: {clienteId: this.clienteId}
        },
        columns: [
          {
            name: 'identificacao', headerName: 'Identificacao', resource: (val: any, row: ContainerClienteEntity) => {
              if (!!row.dataDesativacao) {
                return `<span style="text-decoration: line-through; opacity: .6">${val}</span>`
              }
              return val
            }
          },
          {
            name: 'galpao', headerName: 'Transportador', resource: (val: GalpaoEntity, row: ContainerClienteEntity) => {
              if (!!row?.dataDesativacao) {
                return `<span style="text-decoration: line-through; opacity: .6">${val?.nome || '--'}</span>`
              }
              return val?.nome || '--'
            }
          },
          {
            name: 'tipoLixoContainer',
            headerName: 'Tipo do Resíduo',
            resource: (val: any, row: ContainerClienteEntity) => {
              if (!!row.dataDesativacao) {
                return `<span style="text-decoration: line-through; opacity: .6">${val?.nome || '--'}</span>`
              }
              return val?.nome || '--'
            }
          },
          {
            name: 'container',
            headerName: 'Recipiente',
            resource: (val: ContainerEntity, row: ContainerClienteEntity) => {
              if (!!row.dataDesativacao) {
                return `<span style="text-decoration: line-through; opacity: .6">${!!val?.nome ? val.nome : '--'}</span>`
              }
              return !!val?.nome ? val.nome : '--'
            }
          },
        ],
        filters: {
          group: 'cliente-relacao-contrato',
          reactive: false,
          filters: {
            isAtivo: {data: true}
          }
        },
        actions: {
          edit: {
            name: 'Visualizar', action: (val: ContainerClienteEntity) => {
              this.modalRelacaoContrato.open(val).then()
            }
          },
          ativar: {
            name: 'Ativar',
            action: (row: ContainerClienteEntity) => {
              if (!row?.id) return;
              this.actionsTables.relacaoContrato.aditaInativa(row.id, true)
            },
            permission: (row: ContainerClienteEntity) => {
              return !!row.dataDesativacao && this.session.checkPerfil(
                ['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'EMPRESA', 'CASA', 'EVENTO', 'GALPAO']
              );
            }
          },
          desativar: {
            name: 'Desativar',
            action: (row: ContainerClienteEntity) => {
              if (!row?.id) return;
              this.actionsTables.relacaoContrato.aditaInativa(row.id, false)
            },
            permission: (row: ContainerClienteEntity) => {
              return !row.dataDesativacao && this.session.checkPerfil(
                ['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'EMPRESA', 'CASA', 'EVENTO', 'GALPAO']
              );
            }
          },
        },
        sort: true
      })
    }

    if (!!this.clienteId && !this.tableMissoesAceitas) {
      this.tableMissoesAceitas = this.createTable.create('request', {
        apiData: {
          path: environment.apiUrl,
          context: 'clientes/missoes/'+this.clienteId
        },
        columns: [
          { name: 'nome', headerName: 'Nome' }
        ]
      })
    }

    this.http.get(GALPAO_FIND_FILTER, { params: { unpaged: true } }).subscribe(
      resp => {
        //@ts-ignore
        this.listGalpao = resp.data;
      }
    )
  }

  actionsTables = {
    relacaoContrato: {
      aditaInativa: (id: number | string, status: boolean) => {
        Swal.fire({
          icon: 'question',
          title: 'Você deseja ' + (!status ? 'desativar' : 'ativar') + ' o container?',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            const data = {
              "ativado": status,
              "clienteContainerId": id
            }
            this.http.patch('cliente-containers/status-ativacao', data).subscribe(
              () => {
                this.tableRelacaoContrato.find()
              }
            )
          }
        })
      }
    }
  }

  verify = {
    permissionAddContainer: () => {
      if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'CONDOMINIO', 'CASA', 'EMPRESA', 'EVENTO'])) {
        return true;
      }

      // if (!this.entity?.id) return true

      if (this.session.checkPerfil('PARCEIRO')) {
        return !!this.entity?.isCreated;
      }

      if (this.session.checkPerfil('GALPAO')) {
        return !!this.entity?.exclusivo;
      }

      return false;
    }
  }

  editDiasSemana(valor: string) {
    if (!this.entity) return;
    this.checkedDiasColetas = true;
    const index = this.entity.diasColeta.findIndex(arrayDia => arrayDia == valor);

    if (index == -1) {
      this.entity.diasColeta.push(valor)
      return;
    }

    this.entity.diasColeta.splice(index, 1);

    return;
  }

  verifyCheckBox(valor: string): boolean {
    const index = this.entity?.diasColeta.findIndex(arrayDia => arrayDia == valor);

    return index != -1;
  }

  verifyBeforeSave():boolean {
    if (this.entity?.diasColeta.length == 0) {
      this.error = true;
      return false;
    }

    this.error = false;
    return true;
  }

  setData(data: {[key:string]: any}) {
    this.form.patchValue({
      galpao: data['galpao']['id'] || '',
      exclusivo: data['exclusivo'] || false
    });
  }

  getData() {
    const form = this.form.getRawValue();

    form['galpao'] = (!form?.galpao || form.galpao == '--') ? null : { id: form.galpao };

    if (!form['galpao']) {
      form['exclusivo'] = false;
    }

    return form;
  }
}
