import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicadores-main',
  templateUrl: './indicadores-main.component.html',
  styleUrls: ['./indicadores-main.component.scss'],
})
export class IndicadoresMainComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }
}
