<div id="indicadores" class="container">
  <div class="row">
    <div
      class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
      style="opacity: .5"
      matTooltip="Em breve"
    >
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title p-0 m-0 f-bold fc-black">Indicadores Regionais</h4>
        </div>
        <div class="card-body">
          <p class="m-0">Indicadores comparativos de impacto com estados e Brasil</p>
        </div>
        <div class="card-footer">
          <span>Impacto</span>
        </div>
      </div>
    </div>
    <div
      class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
      style="opacity: .5"
      matTooltip="Em breve"
    >
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title p-0 m-0 f-bold fc-black">Energia KW</h4>
        </div>
        <div class="card-body">
          <p class="m-0">Medição de energia gasta por metro quadrado construido</p>
        </div>
        <div class="card-footer">
          <span class="" disabled>RIMA</span>
        </div>
      </div>
    </div>
  </div>
</div>
