<!-- BARRA DE PROGRESSO -->
<div id="loader-dashboard" *ngIf="controlles.loaders.showLoader" style="margin: .5rem auto">
  <mat-progress-bar mode="buffer" [value]="controlles.loaders.progress().percent"></mat-progress-bar>
</div>

<!-- CONTEUDO DA TELA -->
<div class="container-fluid" *ngIf="verifyShow.hasAnyDados">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12">
              <div class="row justify-content-between">
                <div class="col-6">
                  <card>
                    <div class="card-body">
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold">Número de clientes</span>
                      </div>
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold fs-15">{{dataDashboard.cardInfo.numeroClientes}}</span>
                      </div>
                    </div>
                  </card>
                </div>

                <div class="col-6">
                  <card>
                    <div class="card-body">
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold">Ambientes</span>
                      </div>
                      <div class="w-100 d-inline-flex justify-content-center">
                        <span class="destaque-claro f-bold fs-15">{{dataDashboard.cardInfo.numeroApartmentos}}</span>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>

  <div class="row mt-2"
       *ngIf="controlles.loaders.getItemLoader('anos').finish && !controlles.loaders.getItemLoader('anos').error">
    <div class="col-6">
      <div id="id-chart-anos"></div>
    </div>
    <div class="col-6">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Peso da coleta (TON)</th>
          <th>Peso recebido (TON)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.ano || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.seriesData?.estimativa}}</td>
          <td>{{item?.seriesData?.peso}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>

  <div class="row mt-2"
       *ngIf="controlles.loaders.getItemLoader('tipoResiduos').finish && !controlles.loaders.getItemLoader('tipoResiduos').error">
    <div class="col-12">
      <h4></h4>
    </div>
    <div class="col-4">
      <div id="id-chart-residuos"></div>
    </div>
    <div class="col-8">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Percentual</th>
          <th>Peso em (ton)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.residuos || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.percentage}}%</td>
          <td>{{item?.y |json}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>

  <div class="row mt-2"
       *ngIf="controlles.loaders.getItemLoader('meses').finish && !controlles.loaders.getItemLoader('meses').error">
    <!--    <div class="col-12">-->
    <!--      <h4>Meses</h4>-->
    <!--    </div>-->
    <div class="col-12">
      <div id="id-chart-meses"></div>
    </div>
    <div class="col-12">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Peso da coleta (TON)</th>
          <th>Peso recebido (TON)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.meses || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.seriesData?.estimativa}}</td>
          <td>{{item?.seriesData?.peso}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>

  <div class="row mt-2"
       *ngIf="controlles.loaders.getItemLoader('totais').finish && !controlles.loaders.getItemLoader('totais').error">
    <div class="col-12">
      <div id="id-chart-totais"></div>
    </div>
    <div class="col-12">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Peso da coleta (TON)</th>
          <th>Peso recebido (TON)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.totais || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.seriesData?.estimativa}}</td>
          <td>{{item?.seriesData?.peso}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>

  <div class="row mt-2">
    <div class="col-12">
    </div>
    <div class="col-12">
      <div id="id-chart-motoristas"></div>
    </div>
    <div class="col-12">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Peso da coleta (TON)</th>
          <th>Peso recebido (TON)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.motoristas || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.seriesData?.estimativa}}</td>
          <td>{{item?.seriesData?.peso}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div id="id-chart-tratamento"></div>
    </div>
    <div class="col-12">
      <table class="dg-table">
        <thead>
        <tr>
          <th>Nome</th>
          <th>Peso da coleta (TON)</th>
          <th>Peso recebido (TON)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (dataDashboard?.dataGraphs?.tratamento || [])">
          <td>{{item?.name}}</td>
          <td>{{item?.seriesData?.estimativa}}</td>
          <td>{{item?.seriesData?.peso}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <hr>
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <div id="id-chart-gruposResiduos"></div>
    </div>
    <hr>
  </div>



  <div class="row mt-2">
    <div class="col-12">
      <div id="id-chart-aterro-zero"></div>
    </div>
    <hr>
  </div>

  <div class="row d-flex align-items-stretch">
    <!--    <div-->
    <!--      *ngIf="controlles.loaders.getItemLoader('anos').finish && !controlles.loaders.getItemLoader('anos').error"-->
    <!--      class="col-12 mt-1 mb-1"-->
    <!--    >-->
    <!--      <card>-->
    <!--        <div class="card-header">-->
    <!--          <h4>Ano</h4>-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <div class="row">-->
    <!--            <div class="col-6">-->
    <!--              <div id="id-chart-anos"></div>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <table class="dg-table">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th>Nome</th>-->
    <!--                  <th>Peso da coleta (TON)</th>-->
    <!--                  <th>Peso recebido (TON)</th>-->
    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                <tr *ngFor="let item of (dataDashboard?.dataGraphs?.ano || [])">-->
    <!--                  <td>{{item?.name}}</td>-->
    <!--                  <td>{{item?.seriesData?.estimativa}}</td>-->
    <!--                  <td>{{item?.seriesData?.peso |json}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </card>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      *ngIf="controlles.loaders.getItemLoader('tipoResiduos').finish && !controlles.loaders.getItemLoader('tipoResiduos').error"-->
    <!--      class="col-12 mt-1 mb-1"-->
    <!--    >-->
    <!--      <card>-->
    <!--        <div class="card-header">-->
    <!--          <h4>Resíduos</h4>-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <div class="row">-->
    <!--            <div class="col-4">-->
    <!--              <div id="id-chart-residuos"></div>-->
    <!--            </div>-->
    <!--            <div class="col-8">-->
    <!--              <table class="dg-table">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th>Nome</th>-->
    <!--                  <th>Percentual</th>-->
    <!--                  <th>Peso em (ton)</th>-->
    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                <tr *ngFor="let item of (dataDashboard?.dataGraphs?.residuos || [])">-->
    <!--                  <td>{{item?.name}}</td>-->
    <!--                  <td>{{item?.percentage}}%</td>-->
    <!--                  <td>{{item?.y |json}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </card>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      *ngIf="controlles.loaders.getItemLoader('meses').finish && !controlles.loaders.getItemLoader('meses').error"-->
    <!--      class="col-12 mt-1 mb-1 graph-meses"-->
    <!--    >-->
    <!--      <card>-->
    <!--        <div class="card-header">-->
    <!--          <h4>Meses</h4>-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <div class="row">-->
    <!--            <div class="col-12">-->
    <!--              <div id="id-chart-meses"></div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row">-->
    <!--            <div class="col-12">-->
    <!--              <table class="dg-table">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th>Nome</th>-->
    <!--                  <th>Peso da coleta (TON)</th>-->
    <!--                  <th>Peso recebido (TON)</th>-->
    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                <tr *ngFor="let item of (dataDashboard?.dataGraphs?.meses || [])">-->
    <!--                  <td>{{item?.name}}</td>-->
    <!--                  <td>{{item?.seriesData?.estimativa}}</td>-->
    <!--                  <td>{{item?.seriesData?.peso |json}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </card>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      *ngIf="controlles.loaders.getItemLoader('totais').finish && !controlles.loaders.getItemLoader('totais').error"-->
    <!--      class="col-12 mt-1 mb-1"-->
    <!--    >-->
    <!--      <card>-->
    <!--        <div class="card-header">-->
    <!--          <h4>Totais</h4>-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <div class="row">-->
    <!--            <div class="col-12">-->
    <!--              <div id="id-chart-totais"></div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="row">-->
    <!--            <div class="col-12">-->
    <!--              <table class="dg-table">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th>Nome</th>-->
    <!--                  <th>Peso da coleta (TON)</th>-->
    <!--                  <th>Peso recebido (TON)</th>-->
    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                <tr *ngFor="let item of (dataDashboard?.dataGraphs?.totais || [])">-->
    <!--                  <td>{{item?.name}}</td>-->
    <!--                  <td>{{item?.seriesData?.estimativa}}</td>-->
    <!--                  <td>{{item?.seriesData?.peso |json}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </card>-->
    <!--    </div>-->
<!--    <div-->
<!--      class="col-12 mt-1 mb-1"-->
<!--      *ngIf="controlles.loaders.getItemLoader('motoristas').finish && !controlles.loaders.getItemLoader('motoristas').error"-->
<!--    >-->
<!--      <card>-->
<!--        <div class="card-header">-->
<!--          <h4>Motorista/Catador</h4>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <div id="id-chart-motoristas"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <table class="dg-table">-->
<!--                <thead>-->
<!--                <tr>-->
<!--                  <th>Nome</th>-->
<!--                  <th>Peso da coleta (TON)</th>-->
<!--                  <th>Peso recebido (TON)</th>-->
<!--                </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                <tr *ngFor="let item of (dataDashboard?.dataGraphs?.motoristas || [])">-->
<!--                  <td>{{item?.name}}</td>-->
<!--                  <td>{{item?.seriesData?.estimativa}}</td>-->
<!--                  <td>{{item?.seriesData?.peso |json}}</td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </card>-->
<!--    </div>-->
<!--    <div-->
<!--      class="col-12 mt-1 mb-1"-->
<!--      *ngIf="controlles.loaders.getItemLoader('lixoZero').finish && !controlles.loaders.getItemLoader('lixoZero').error"-->
<!--    >-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->
<!--          <h4>Gráfico de lixo zero</h4>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <div id="id-chart-aterro-zero"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div class="row" *ngIf="controlles.listLoaderPermission.includes('tableGerador')">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <h4 class="card-title">Detalhamento por Gerador</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body body-table">
          <!--          <dg-table dg-dynamic-table [dynamicTable]="tableGerador" [initAutoLoader]="false"></dg-table>-->
        </div>
        <div class="card-footer d-inline-flex justify-content-center">
          <!--          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableGerador"></dg-paginator>-->
        </div>
      </div>
    </div>
  </div>
</div>

