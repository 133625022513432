<div id="galpao-main" class="container">
  <div class="row">
    <div class="col-12">
      <div class="card card-root">
        <div class="card-header">
          <h4 class="card-title">Geradores</h4>
        </div>
        <div class="card-subtitle">
          <filter-search2 dynamicGroupName="clientes">
            <form [formGroup]="formFilters">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <label class="form-label">Nome</label>
                  <default-autocomplete-input
                    formControlName="clienteId"
                    label="nome"
                    key="id"
                    dg-dynamic-group="clientes"
                    paramName="clienteId"
                    [data]="listClientes"
                    typeFilter="outside"
                    (searchFilterValue)="searchFilter($event, 'cliente')"
                  >
                  </default-autocomplete-input>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngIf="session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])">
                  <label class="form-label">Parceiro</label>
                  <default-autocomplete-input
                    formControlName="parceiroId"
                    label="nome"
                    key="id"
                    dg-dynamic-group="clientes"
                    paramName="parceiroId"
                    [data]="listParceiros"
                    typeFilter="outside"
                    (searchFilterValue)="searchFilter($event, 'parceiro')"
                  >
                  </default-autocomplete-input>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label class="form-label">Ativo?</label>
                  <select
                    class="form-select"
                    formControlName="ativos"
                    dg-dynamic-group="clientes"
                    paramName="ativos"
                  >
                    <option value="">Ambos</option>
                    <option [value]="true">Sim</option>
                    <option [value]="false">Não</option>
                  </select>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                  <label class="form-label">Possui administrador?</label>
                  <select
                    class="form-select"
                    formControlName="possuiSindico"
                    dg-dynamic-group="clientes"
                    paramName="possuiSindico"
                  >
                    <option value="">Ambos</option>
                    <option [value]="true">Sim</option>
                    <option [value]="false">Não</option>
                  </select>
                </div>
              </div>
            </form>
          </filter-search2>
        </div>
        <div class="card-body" style="padding: 0">
          <dg-table class="default-table" dg-dynamic-table [dynamicTable]="table"></dg-table>
        </div>
        <div class="card-footer d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <card>-->
<!--        <div class="card-header">-->
<!--          <h4 class="card-title">Geradores</h4>-->
<!--        </div>-->
<!--        <div class="card-subtitle">-->
<!--          <filter-search [entityMap]="mapEntity" [table]="tableCliente" tableEventId="clienteTable"></filter-search>-->
<!--        </div>-->
<!--        <div class="card-body" style="padding: 0">-->
<!--          <crud-data-table-->
<!--            #tableCliente-->
<!--            [entityMap]="mapEntity"-->
<!--            [showPagination]="false"-->
<!--            tableEventId="clienteTable"-->
<!--            [register]="true"-->
<!--            [customActions]="session.checkPerfil('PARCEIRO') ? tableClientes: undefined "-->
<!--            ></crud-data-table>-->
<!--          <div class="d-flex justify-content-center card-footer">-->
<!--            <dg-pagination-->
<!--              [dataPagination]="mapEntity.configEntityTable.pagination"-->
<!--              (changePage)="tableCliente.changePages($event)"-->
<!--            ></dg-pagination>-->
<!--          </div>-->
<!--        </div>-->
<!--      </card>-->
<!--    </div>-->
<!--  </div>-->
</div>


<page-footer *ngIf="!session.checkProfile('USUARIO_CONSULTA')">
<!--  <button crud-find-filters class="btn btn-success">Pesquisar</button>-->
<!--  <button crud-table-clear class="btn btn-secondary">Limpar</button>-->
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
   <button (click)="baixarPlanilha()" class="btn btn-secondary">Baixar planilha</button>
</page-footer>
