<old-dg-modal
  #modalDocumentos
  size="md"
  title="Dados do Arquivo"
  [afterClose]="afterClose"
>
  <div dg-content class="modal-galpao-documentos">
    <div class="row">
      <div *ngIf="!!entityArquivo" class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-btn">
        <label class="form-label w-100">
          {{entityArquivo?.arquivo?.fileNameOriginal}}
        </label>
        <button (click)="download(entityArquivo)" class="btn btn-secondary w-100">Download</button>
      </div>

      <div *ngIf="!entityArquivo" class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-btn">
        <label class="form-label w-100">
          {{ arquivoName || 'Arquivo'}}
        </label>
        <input
          #docInput
          type="file"
          accept=".pdf"
          hidden
          [(ngModel)]="inputArquivo"
          (change)="uploadDocumento($event)"
        >
        <button (click)="docInput.click()" class="btn btn-success w-100">Escolher</button>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <label for="data-pagamento" class="form-label">Data de inclusão <span class="fc-red">*</span></label>
        <input type="date" (paste)="ajustePasteDate($event)" [formControl]="dataPagamento" id="data-pagamento" class="form-control">
      </div>
      <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12">
        <label class="form-label w-100">
          Utilizado?
        </label>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="pago"
            [formControl]="pago"
          >
          <label class="form-check-label" for="pago">
            Sim
          </label>
        </div>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modalDocumentos.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
