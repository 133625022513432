import { Injectable } from '@angular/core';
import {
  CLIENTE_ESTADOS,
  DASHBOARD_FILTER_OPERADOR_LOGISTICO, DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
  DASHBOARD_FILTER_UNIDADE_GERADORA, GALPAO_MOTORISTA_NOME
} from "../../../../../core/config/endpoint-list";
import {HttpService} from "../../../../../services/http-service/http.service";
import {FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

declare type listKey = 'geradores' | 'transportadores' | 'residuos' | 'operadores' | 'motoristas' | 'estados'
  | 'gruposResiduos' |  'tratamento'

interface ChartData {
  name: string;
  seriesData: { [key: string]: number };
}

interface ChartDataPoint {
  y: number;
  name: string;
  color?: string;
  percentage?: number;
}

interface ChartConfig {
  charts: {
    plotOptions: {
      pie: {
        data: ChartDataPoint[];
      };
    };
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class DashboardFilterHelperService {

  constructor(private http: HttpService) {
  }

  loaderListFavoritos(list: 'coletas' | 'estoque' | 'ranking', params: {[key:string]: any}, callback: (list:any[]) => void) {
    const tipoFavorito:{[key:string]: string} = {
      coletas: 'DASHBOARD_COLETAS',
      estoque: 'DASHBOARD_ESTOQUE',
      ranking: 'RANKING_TAXA_RECICLAGEM',
    }
    this.http.get(
      'favoritos',
      {
        params: { unpaged: true, tipoFavorito: tipoFavorito[list], ...(params || {}) }
      }).subscribe(
      resp => {
        const result = (resp.data || []).sort(
          (a: any, b: any) => {
            return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
          }
        )

        callback(result)
      }
    );
  }

  loaderList2(list: listKey, params: {[key:string]: any} | undefined | null, callback: (list:any[]) => void) {
    const endpoints: {
      geradores: string,
      transportadores: string
      residuos: string
      operadores: string
      motoristas: string
      estados: string
      gruposResiduos: string
      tratamento: string
    } = {
      geradores: DASHBOARD_FILTER_UNIDADE_GERADORA,
      transportadores: DASHBOARD_FILTER_OPERADOR_LOGISTICO,
      residuos: DASHBOARD_FILTER_OPERADOR_TIPOLIXO,
      operadores: DASHBOARD_FILTER_OPERADOR_LOGISTICO,
      motoristas: GALPAO_MOTORISTA_NOME,
      estados: CLIENTE_ESTADOS,
      gruposResiduos: 'grupos-tipos-residuos/nomes',
      tratamento: 'tratamento/nomes',
    }

    return this.http.get(
      endpoints[list],
      {
        params: { unpaged: true, ...(params || {}) }
      }).subscribe(
      resp => {
        const result = (resp.data || []).sort(
          (a: any, b: any) => {
            return ('' + a.nome?.toUpperCase().trim()).localeCompare(b.nome?.toUpperCase().trim());
          }
        )

        callback(result)
      }
    );
  }

  // TODO rever esse metodo conforme for incrementando as funções
  minimumToFilter(baseValue: {[key:string]: any}, filters: {[key:string]: any}): boolean {
    return JSON.stringify(baseValue) != JSON.stringify(filters)
  }

  getFormFilterValues(form: FormGroup, byRequest: boolean = false) {
    const result = form.getRawValue();

    Object.keys(result).map(itemForm => {
      if (Array.isArray(result[itemForm])) {
        if (result[itemForm].length <= 0) {
          delete result[itemForm]
        } else if (byRequest) {
          result[itemForm] = result[itemForm]?.join(',')
        }
      } else if (!result[itemForm]) {
        delete result[itemForm]
      }
    })

    return result;
  }


  // limpa os requestas seja para que buscas possam ser canceladas após o disparo.
  // como todas as telas devem implementar uma lista, criei esse metodo para auxiliar na limpeza de todas elas
  public clearRequests(listRequests: {[key: string]: Subscription | undefined}) {
    Object.keys(listRequests).map((request: string) => {
      if (request in listRequests) {
        if (!!listRequests[request]) {
          listRequests[request]?.unsubscribe();
          listRequests[request] = undefined;
        }
      }
    })
  }

  public extractChartData(chart: any): ChartData[] {
    console.log('extractedData', chart)

    const series = chart.series;
    const categories = chart.xAxis[0].categories;

    const extractedData: ChartData[] = [];

    categories.forEach((category: string, index: number) => {
      const row: ChartData = { name: category, seriesData: {} };
      series.forEach((serie: any) => {
        row.seriesData[serie.name] = serie.data[index].y;
      });
      extractedData.push(row);
    });

    return extractedData.map(item => {

      // if ('Estimativa (ton)' in item.seriesData) {
      //   item.seriesData['estimativa'] = item.seriesData['Estimativa (ton)']
      //   delete item.seriesData['Estimativa (ton)'];
      // }
      if ('Estoque' in item.seriesData) {
        item.seriesData['estoque'] = item.seriesData['Estoque']
        delete item.seriesData['Estoque'];
      }
      if ('Total de reciclagem do operador' in item.seriesData) {
        item.seriesData['totalOperador'] = item.seriesData['Total de reciclagem do operador']
        delete item.seriesData['Total de reciclagem do operador'];
      }
      if (item.seriesData['Total de reciclagem do estado (ES,MG,RJ,SP)']) {
        item.seriesData['totalEstados'] = item.seriesData['Total de reciclagem do estado (ES,MG,RJ,SP)']
        delete item.seriesData['Total de reciclagem do estado (ES,MG,RJ,SP)'];
      }
      if ('Peso da coleta (TON)' in item.seriesData) {
        item.seriesData['estimativa'] = item.seriesData['Peso da coleta (TON)']
        delete item.seriesData['Peso da coleta (TON)'];
      }
      if ('Peso recebido (TON)' in item.seriesData || 'Peso (ton)' in item.seriesData) {
        item.seriesData['peso'] = item.seriesData['Peso recebido (TON)'] || item.seriesData['Peso (ton)']
        // delete item.seriesData['Peso recebido (TON)'];
      }


      return item;
    });
  }

  /**
   * Metodo de extração de dados para higchart PIE.
   * Esse método sempre retornará um array com cada nivel sendo os dados
   * @param chartConfig
   */
  public extractChartPie(chartConfig: ChartConfig): ChartDataPoint[] {
    try {
      const data = chartConfig.charts[0]?.plotOptions?.pie?.data;

      if (!data || !Array.isArray(data)) return [];

      const total = data.reduce((sum, point) => sum + (point?.y || 0), 0);

      return data.map(point => {
        const yValue = point?.y || 0;
        if (yValue == null) return { ...point, y: 0, percentage: 0 };

        const percentage = (yValue / total) * 100;
        return {
          ...point,
          y: parseFloat(yValue.toFixed(2)),
          percentage: parseFloat(percentage.toFixed(2)),
        };
      });
    } catch (error) {
      console.error("Error extracting chart data:", error);
      return [];
    }
  }
}
